import api from 'services/api'
import { gql } from '@apollo/client'
import { USER } from 'services/gpl/user'
import {
	CreateOrganizationMutation,
	CreateOrganizationUserMutation,
	OrgOptinTriggerKeywordFields,
	OrganizationsQuery,
	ToggleOptinFlowFields,
	ToggleNotificationFields,
	UpdateOrganizationBillingMutation,
	UpdateOrganizationMutation,
	toggleOptions,
	UpdateOrganizationLogoMutation,
	UpdateOrganizationNameMutation,
	UpdateOrganizationAdvisorNameMutation,
} from './organization.types'
import { Organization } from 'types/api/organization.model'
import { User } from 'types/api/user.model'
import { ORGANIZATION } from 'services/gpl/organization'

export class OrganizationService {
	private CREATE_ORGANIZATION = gql`
		mutation createOrganization($input: CreateOrganizationInput!) {
			createOrganization(input: $input) {
				id
				${ORGANIZATION}
			}
		}
	`

	private UPDATE_ORGANIZATION = gql`
		mutation updateOrganization($input: UpdateOrganizationInput!) {
			updateOrganization(input: $input) {
				id
				${ORGANIZATION}
			}
		}
	`
	private UPDATE_ORGANIZATION_NAME = gql`
		mutation updateOrganizationName($input: UpdateOrganizationNameInput!) {
			updateOrganizationName(input: $input) {
				id
				${ORGANIZATION}
			}
		}
	`
	private UPDATE_ORGANIZATION_ADVISOR_NAME = gql`
		mutation updateOrganizationAdvisorName($input: UpdateOrganizationAdvisorNameInput!) {
			updateOrganizationAdvisorName(input: $input) {
				id
				${ORGANIZATION}
			}
		}
	`

	private UPDATE_ORGANIZATION_LOGO = gql`
		mutation updateOrganizationLogo($input: UpdateOrganizationLogoInput!) {
			updateOrganizationLogo(input: $input) {
				id
				${ORGANIZATION}
			}
		}
	`

	private UPDATE_ORGANIZATION_BILLING = gql`
		mutation updateOrganizationBilling($input: UpdateOrganizationBillingInput!) {
			updateOrganizationBilling(input: $input) {
				id
				${ORGANIZATION}
			}
		}
	`

	private CREATE_ORGANIZATION_USER = gql`
		mutation createOrganizationUser($input: CreateOrganizationUserInput!) {
			createOrganizationUser(input: $input) {
			    ${USER}
			}
		}
	`

	private TOGGLE_OPTIN_FLOW = gql`
		mutation toggleOptinFlow($input:  ToggleOptinFlowInput!){
			toggleOptinFlow(input: $input){
				${ORGANIZATION}
			}
		}
	`
	private TOGGLE_NOTIFICATION = gql`
		mutation toggleNotificationSettings($input:  ToggleNotificationSettingsInput!){
			toggleNotificationSettings(input: $input){
				${ORGANIZATION}
			}
		}
	`

	private GET_ORGANIZATIONS = gql`
		query listOrganizations($root: Boolean, $parentId: ID) {
			listOrganizations(root: $root, parentId: $parentId) {
				id
				name
			}
		}
	`
	private UPDATE_OPTIN_TRIGGER_KEYWORD = gql`
		mutation updateOptinTriggerKeyword($input: UpdateOptinTriggerKeywordInput!) {
			updateOptinTriggerKeyword(input: $input) {
				${ORGANIZATION}
			}
		}
	`

	private TOGGLE_OPTIN_BY_SMS = gql`
		mutation toggleOptinByFirstSms($input: ToggleOptinByFirstSmsInput!) {
			toggleOptinByFirstSms(input: $input) {
				${ORGANIZATION}
			}
		}
	`

	private TOGGLE_OPTIN_BY_KEYWORD = gql`
		mutation toggleOptinTrigger($input: ToggleOptinTriggerInput!) {
			toggleOptinTrigger(input: $input) {
				${ORGANIZATION}
			}
		}
	`

	private TOGGLE_RESTRICT_BULK_MSGS = gql`
		mutation toggleRestrictBulkMsgs($input:  ToggleRestrictBulkMsgsInput!){
			toggleRestrictBulkMsgs(input: $input){
				${ORGANIZATION}
			}
		}
	`

	private TOGGLE_RESTRICT_All_MSGS = gql`
		mutation toggleRestrictAllMsgs($input:  ToggleRestrictAllMsgsInput!){
			toggleRestrictAllMsgs(input: $input){
				${ORGANIZATION}
			}
		}
	`

	private TOGGLE_AUTOSEND_OUTBOUND_MSGS = gql`
		mutation toggleAutosendOutboundMsgs($input:  ToggleAutosendOutboundMsgsInput!){
			toggleAutosendOutboundMsgs(input: $input){
				${ORGANIZATION}
			}
		}
	`
	private TOGGLE_ALLOW_CONTACTS_OPTIN = gql`
		mutation toggleContactsOptInSetting($input:  ToggleContactsOptInSettingInput!){
			toggleContactsOptInSetting(input: $input){
				${ORGANIZATION}
			}
		}
	`

	createOrganization = async (
		fields: CreateOrganizationMutation
	): Promise<Organization> => {
		return api.client
			.mutate<{ createOrganization: Organization }>({
				mutation: this.CREATE_ORGANIZATION,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				return res.data && res.data.createOrganization
			})
	}

	updateOrganization = async (
		fields: UpdateOrganizationMutation
	): Promise<Organization> => {
		return api.client
			.mutate<{ updateOrganization: Organization }>({
				mutation: this.UPDATE_ORGANIZATION,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				return res.data && res.data.updateOrganization
			})
	}

	updateOrganizationName = async (
		fields: UpdateOrganizationNameMutation
	): Promise<Organization> => {
		return api.client
			.mutate<{ updateOrganizationName: Organization }>({
				mutation: this.UPDATE_ORGANIZATION_NAME,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				return res.data && res.data.updateOrganizationName
			})
	}

	updateOrganizationAdvisorName = async (
		fields: UpdateOrganizationAdvisorNameMutation
	): Promise<Organization> => {
		return api.client
			.mutate<{ updateOrganizationAdvisorName: Organization }>({
				mutation: this.UPDATE_ORGANIZATION_ADVISOR_NAME,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				return res.data && res.data.updateOrganizationAdvisorName
			})
	}

	updateOrganizationLogo = async (
		fields: UpdateOrganizationLogoMutation
	): Promise<Organization> => {
		return api.client
			.mutate<{ updateOrganizationLogo: Organization }>({
				mutation: this.UPDATE_ORGANIZATION_LOGO,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				return res.data && res.data.updateOrganizationLogo
			})
	}

	updateOrganizationBilling = async (
		fields: UpdateOrganizationBillingMutation
	): Promise<Organization> => {
		return api.client
			.mutate<{ updateOrganizationBilling: Organization }>({
				mutation: this.UPDATE_ORGANIZATION_BILLING,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				return res.data && res.data.updateOrganizationBilling
			})
	}

	createOrganizationUser = async (
		fields: CreateOrganizationUserMutation
	): Promise<User | null> => {
		return api.client
			.mutate<
				{ createOrganizationUser: User | null },
				CreateOrganizationUserMutation
			>({
				mutation: this.CREATE_ORGANIZATION_USER,
				variables: fields,
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				return res.data && res.data.createOrganizationUser
			})
	}

	toggleOptinFlow = async (
		fields: ToggleOptinFlowFields
	): Promise<Organization> => {
		return await api.client
			.mutate<
				{ toggleOptinFlow: Organization },
				{ input: ToggleOptinFlowFields }
			>({
				mutation: this.TOGGLE_OPTIN_FLOW,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.toggleOptinFlow
			})
	}
	toggleNotificationSettings = async (
		fields: ToggleNotificationFields
	): Promise<Organization> => {
		return await api.client
			.mutate<
				{ toggleNotificationSettings: Organization },
				{ input: ToggleNotificationFields }
			>({
				mutation: this.TOGGLE_NOTIFICATION,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.toggleNotificationSettings
			})
	}

	getOrganizations = async (
		fields: OrganizationsQuery
	): Promise<Organization[]> => {
		return await api.client
			.query({
				query: this.GET_ORGANIZATIONS,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response && response.data && response.data.listOrganizations
			})
	}

	updateOrganizationOptinKeyword = async (
		fields: OrgOptinTriggerKeywordFields
	): Promise<Organization> => {
		return await api.client
			.mutate<
				{ updateOptinTriggerKeyword: Organization },
				{ input: OrgOptinTriggerKeywordFields }
			>({
				mutation: this.UPDATE_OPTIN_TRIGGER_KEYWORD,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return (
					response && response.data && response.data.updateOptinTriggerKeyword
				)
			})
	}

	toggleOptinByOrgKeyword = async (
		fields: toggleOptions
	): Promise<Organization> => {
		return await api.client
			.mutate<{ toggleOptinTrigger: Organization }, { input: toggleOptions }>({
				mutation: this.TOGGLE_OPTIN_BY_KEYWORD,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response && response.data && response.data.toggleOptinTrigger
			})
	}

	toggleOptinByFirstSms = async (
		fields: toggleOptions
	): Promise<Organization> => {
		return await api.client
			.mutate<
				{ toggleOptinByFirstSms: Organization },
				{ input: toggleOptions }
			>({
				mutation: this.TOGGLE_OPTIN_BY_SMS,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response && response.data && response.data.toggleOptinByFirstSms
			})
	}

	toggleRestrictBulkMsgs = async (
		fields: toggleOptions
	): Promise<Organization> => {
		return await api.client
			.mutate<
				{ toggleRestrictBulkMsgs: Organization },
				{ input: toggleOptions }
			>({
				mutation: this.TOGGLE_RESTRICT_BULK_MSGS,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response && response.data && response.data.toggleRestrictBulkMsgs
			})
			.catch(error => {
				throw new Error(error)
			})
	}

	toggleRestrictAllMsgs = async (
		fields: toggleOptions
	): Promise<Organization> => {
		return await api.client
			.mutate<
				{ toggleRestrictAllMsgs: Organization },
				{ input: toggleOptions }
			>({
				mutation: this.TOGGLE_RESTRICT_All_MSGS,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response && response.data && response.data.toggleRestrictAllMsgs
			})
	}

	toggleAutosendOutboundMsgs = async (
		fields: toggleOptions
	): Promise<Organization> => {
		return await api.client
			.mutate<
				{ toggleAutosendOutboundMsgs: Organization },
				{ input: toggleOptions }
			>({
				mutation: this.TOGGLE_AUTOSEND_OUTBOUND_MSGS,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return (
					response && response.data && response.data.toggleAutosendOutboundMsgs
				)
			})
	}
	toggleContactsOptInSetting = async (
		fields: toggleOptions
	): Promise<Organization> => {
		return await api.client
			.mutate<
				{ toggleContactsOptInSetting: Organization },
				{ input: toggleOptions }
			>({
				mutation: this.TOGGLE_ALLOW_CONTACTS_OPTIN,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return (
					response && response.data && response.data.toggleContactsOptInSetting
				)
			})
	}
}

export default new OrganizationService()
