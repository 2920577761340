import React from 'react'
import {
	DashboardContainer,
	DashboardRow,
	DashboardLogo,
	ConvosText,
} from './MarketingHubItemContainer.styled'
import ArrowLogo from 'modules/App/ArrowLogo'

interface MarketingHubItemContainerProps {
	title: string
	onClick: () => void
	onMouseEnter: () => void
	onMouseLeave: () => void
	isHover: boolean
	iconSrc: string
	hoverColor?: string
}

const MarketingHubItemContainer = ({
	title,
	hoverColor = '#EFEFF1',
	onClick,
	onMouseEnter,
	onMouseLeave,
	isHover,
	iconSrc,
}: MarketingHubItemContainerProps) => {
	return (
		<DashboardContainer
			hoverColor={hoverColor}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<DashboardRow>
				<DashboardLogo src={iconSrc} isHover={isHover} />
				<ConvosText text={title} isHover={isHover} />
			</DashboardRow>

			<div>
				<ArrowLogo hover={isHover} />
			</div>
		</DashboardContainer>
	)
}

export default MarketingHubItemContainer
