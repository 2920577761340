export enum Scenes {
	MainHomeScreen = 'mainHomeScreen',
	SignIn = 'signin',
	SignUp = 'signup',
	ForgotPassword = 'forgotPassword',
	CheckYourEmail = 'checkYourEmail',
	ResetPassword = 'resetPassword',
	Organization = 'organization',
	ContactSync = 'contactSync',
	Landing = 'landing',
	CheckEmail = 'checkEmail',
	CreatingProfile = 'creatingProfile',
	AccountUsage = 'accountUsage',
	Plan = 'plan',
	Phone = 'phone',
	Payment = 'payment',
	// Dashboard = 'dashboard',
	Messages = 'messages',
	// Campaigns = 'campaigns',
	Contacts = 'contacts',
	OptIn = 'optIn',
	WelcomeOptIn = 'welcomeOptIn',
	WelcomeOptOut = 'WelcomeOptOut',
	Widgets = 'widgets',
	Settings = 'settings',
	Profile = 'profile',
	Integration = 'integration',
	MyOrganization = 'myOrganization',
	MyPhoneNumber = 'myPhoneNumber',
	Notifications = 'notifications',
	Billing = 'billing',
	SnappyKrakenConnect = 'snappyKrakenConnect',
	// CampaignCreate = 'campaignCreate',
	// CampaignEdit = 'campaignEdit',
	CampaignKeywordViewReport = 'campaignKeywordViewReport',
	CampaignBroadcastCreate = 'campaignBroadcastCreate',
	CampaignBroadcastEdit = 'campaignBroadcastEdit',
	CampaignBroadcastViewReport = 'campaignBroadcastViewReport',
	CampaignKeywordCreate = 'campaignKeywordCreate',
	ClientRegister = 'clientRegister',
	SystemNumbers = 'systemNumbers',
	PaymentPreview = 'paymentPreview',
	PaymentReview = 'paymentReview',
	CancelMembership = 'cancelMembership',
	ComparePlans = 'comparePlans',
	AutomatedMessages = 'automatedMessages',
	WelcomeOneWay = 'welcomeOneWay',
	GetConversationLink = 'getConversationLink',
	AuthVerify = 'authenticationVerification',
	Setup = 'setup',
	SetupCreateContact = 'setupCreateContact',
	SetupOptinFlow = 'setupOptinFlow',
	SetupFirstMessage = 'setupFirstMessage',
	BroadCast = 'broadcast',
	KeywordAutomation = 'keywordAutomation',
}

interface IMessagesByIdParams {
	screen: string
	id?: string
}
export interface IConversationByPhoneParams {
	id: string
	phone: string
}

export interface IConversationByPhoneParams {
	id: string
	phone: string
}

export const scenes = {
	[Scenes.GetConversationLink]: {
		pattern: '/conversation/:id/:phone',
		url: ({ id, phone }: IConversationByPhoneParams) =>
			`/messages/${id}/${phone}`,
	},
	[Scenes.SignIn]: {
		pattern: '/auth/sign-in',
		url: '/auth/sign-in',
	},
	[Scenes.CreatingProfile]: {
		pattern: '/creating-profile',
		url: '/creating-profile',
	},
	[Scenes.AccountUsage]: {
		pattern: '/account-usage',
		url: '/account-usage',
	},
	[Scenes.Plan]: {
		pattern: '/plan',
		url: '/plan',
	},
	[Scenes.Phone]: {
		pattern: '/phone',
		url: '/phone',
	},
	[Scenes.Payment]: {
		pattern: '/payment',
		url: '/payment',
	},
	[Scenes.PaymentPreview]: {
		pattern: '/payment-preview',
		url: '/payment-preview',
	},
	[Scenes.PaymentReview]: {
		pattern: '/payment-review',
		url: '/payment-review',
	},
	[Scenes.CancelMembership]: {
		pattern: '/cancel-membership',
		url: '/cancel-membership',
	},
	[Scenes.ComparePlans]: {
		pattern: '/compare-plans',
		url: '/compare-plans',
	},
	[Scenes.Landing]: {
		pattern: '/begin',
		url: '/begin',
	},
	[Scenes.CheckEmail]: {
		pattern: '/begin/check-email',
		url: '/begin/check-email',
	},
	[Scenes.SignUp]: {
		pattern: '/convos-begin',
		url: '/convos-begin',
	},
	[Scenes.Organization]: {
		pattern: '/organization',
		url: '/organization',
	},
	[Scenes.ContactSync]: {
		pattern: '/contactSync',
		url: '/contactSync',
	},
	[Scenes.ResetPassword]: {
		pattern: '/users/password/edit',
		url: '/users/password/edit',
	},
	// [Scenes.Dashboard]: {
	// 	pattern: '/dashboard',
	// 	url: '/dashboard',
	// },

	[Scenes.MainHomeScreen]: {
		pattern: '/messages/primary',
		url: '/messages/primary/',
	},
	[Scenes.Messages]: {
		pattern: '/messages/:screen/:id?',
		url: ({ screen, id }: IMessagesByIdParams) =>
			`/messages/${screen}/${id || ''}`,
	},
	// [Scenes.Campaigns]: {
	// 	pattern: '/broadcasts+keywords',
	// 	url: '/broadcasts+keywords',
	// },
	[Scenes.BroadCast]: {
		pattern: '/broadcasts',
		url: '/broadcasts',
	},
	[Scenes.KeywordAutomation]: {
		pattern: '/keyword-automations',
		url: '/keyword-automations',
	},
	[Scenes.Contacts]: {
		pattern: '/contacts/:action?',
		url: (action: string = '') => `/contacts/${action}`,
	},
	[Scenes.WelcomeOptIn]: {
		pattern: '/opt-in-automations/welcome-opt-in',
		url: '/opt-in-automations/welcome-opt-in',
	},
	[Scenes.WelcomeOptOut]: {
		pattern: '/opt-in-automations/welcome-opt-out',
		url: '/opt-in-automations/welcome-opt-out',
	},
	[Scenes.WelcomeOneWay]: {
		pattern: '/opt-in-automations/one-way-opt-in',
		url: '/opt-in-automations/one-way-opt-in',
	},
	[Scenes.Widgets]: {
		pattern: '/widgets',
		url: '/widgets',
	},
	[Scenes.Settings]: {
		pattern: '/settings',
		url: '/settings',
	},
	[Scenes.Profile]: {
		pattern: '/settings/profile',
		url: '/settings/profile',
	},
	[Scenes.OptIn]: {
		pattern: '/settings/optIn',
		url: '/settings/optIn',
	},
	[Scenes.Integration]: {
		pattern: '/settings/integration',
		url: '/settings/integration',
	},
	[Scenes.Notifications]: {
		pattern: '/settings/notifications',
		url: '/settings/notifications',
	},
	[Scenes.MyOrganization]: {
		pattern: '/settings/my-organization',
		url: '/settings/my-organization',
	},
	[Scenes.MyPhoneNumber]: {
		pattern: '/settings/my-phone-number',
		url: '/settings/my-phone-number',
	},
	[Scenes.Billing]: {
		pattern: '/settings/billing',
		url: '/settings/billing',
	},
	[Scenes.SnappyKrakenConnect]: {
		pattern: '/settings/snappy-kraken-connect',
		url: '/settings/snappy-kraken-connect',
	},
	// [Scenes.CampaignCreate]: {
	// 	pattern: '/broadcasts+keywords/create',
	// 	url: '/broadcasts+keywords/create',
	// },
	// [Scenes.CampaignEdit]: {
	// 	pattern: '/broadcasts+keywords/edit/:id',
	// 	url: '/broadcasts+keywords/edit',
	// },
	[Scenes.CampaignBroadcastViewReport]: {
		pattern: '/broadcasts/view-report/:id',
		url: '/broadcasts/view-report/:id',
	},
	[Scenes.CampaignKeywordViewReport]: {
		pattern: '/keyword-automations/view-report/:id',
		url: '/keyword-automations/view-report/:id',
	},
	[Scenes.CampaignBroadcastCreate]: {
		pattern: '/broadcasts/create',
		url: '/broadcasts/create',
	},
	[Scenes.CampaignBroadcastEdit]: {
		pattern: '/broadcasts/edit/:id',
		url: '/broadcasts/edit',
	},
	[Scenes.CampaignKeywordCreate]: {
		pattern: '/keyword-automations/create',
		url: '/keyword-automations/create',
	},
	[Scenes.ClientRegister]: {
		pattern: '/client/register',
		url: '/client/register',
	},
	[Scenes.SystemNumbers]: {
		pattern: '/system-numbers',
		url: '/system-numbers',
	},
	[Scenes.AutomatedMessages]: {
		pattern: '/opt-in-automations',
		url: '/opt-in-automations',
	},
	[Scenes.ForgotPassword]: {
		pattern: '/auth/forgot-password',
		url: '/auth/forgot-password',
	},
	[Scenes.CheckYourEmail]: {
		pattern: '/auth/check-your-email',
		url: '/auth/check-your-email',
	},
	[Scenes.AuthVerify]: {
		pattern: '/auth/verify',
		url: '/auth/verify',
	},
	[Scenes.Setup]: {
		pattern: '/setup',
		url: '/setup',
	},
	[Scenes.SetupCreateContact]: {
		pattern: '/setup-create-contact',
		url: '/setup-create-contact',
	},
	[Scenes.SetupOptinFlow]: {
		pattern: '/setup-optin-flow',
		url: '/setup-optin-flow',
	},
	[Scenes.SetupFirstMessage]: {
		pattern: '/setup-first-message',
		url: '/setup-first-message',
	},
}
