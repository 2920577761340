import {
	ApolloClient,
	split,
	ApolloLink,
	from,
	InMemoryCache,
	NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import credentialsService from 'services/credentials.service'
import { API_URL, isProductionBuild } from '@constants'
import { createUploadLink } from 'apollo-upload-client'
import { relayStylePagination } from '@apollo/client/utilities'
import { getMainDefinition } from '@apollo/client/utilities'
import * as ActionCable from '@rails/actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { SK_AUTH_URL } from '@constants/envs'
import { store } from 'store'
import { isEmpty } from 'lodash'

class ApiService {
	public client: ApolloClient<NormalizedCacheObject>
	private isAuthenticated: boolean = true

	constructor() {
		this.client = this.createClient()
	}

	deleteCookies = async () => {
		try {
			const response = await fetch(`${API_URL}/auth/logout`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			})

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			const responseData = await response.json()
		} catch (error) {
			console.error('Error making POST request:', error)
		}
	}

	handleUnauthorizedError = () => {
		// Perform actions for "invalid user" or "Unauthorized" error
		this.deleteCookies()
		store.dispatch({ type: 'LOG_OUT' })
		// isUninfiedDashboard && window.location.replace(`${SK_AUTH_URL}/?convos`)
		this.isAuthenticated = false // Set authentication status to false after logout
	}

	createClient() {
		const cache = new InMemoryCache({
			typePolicies: {
				Query: {
					fields: {
						fetchConversations: relayStylePagination(),
					},
				},
			},
		})

		const getWebSocketURL = () => {
			const token = credentialsService.getAuthToken()
			return `${API_URL}/cable?token=${token}`
		}

		const _cable = ActionCable.createConsumer(getWebSocketURL())

		const authLink = setContext((_, { headers: originHeaders }) => {
			const headers = { ...originHeaders }

			const token = credentialsService.getAuthToken()

			if (token) {
				headers.Authorization = `Bearer ${token}`
			}

			return { headers }
		})

		// const errorLink = onError(({ graphQLErrors, networkError }) => {
		// 	if (graphQLErrors)
		// 		graphQLErrors.map(({ message, locations, path }) =>
		// 			console.error(
		// 				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`

		// 			)
		// 		)

		// 	if (networkError) console.error(`[Network error]: ${networkError}`)
		// })

		const errorLink = onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors) {
				graphQLErrors.map(({ message, locations, path, extensions }) => {
					console.error(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`
					)

					// Check if the error message contains "invalid user" or "Unauthorized"
					if (
						message.includes('401') ||
						message.includes('invalid user') ||
						message.includes('Unauthorized')
					) {
						if (this.isAuthenticated) {
							// Only handle the error if the user is still authenticated
							this.handleUnauthorizedError()
						}
					}

					if (
						message?.length &&
						extensions &&
						extensions['code'] === 'REDIRECT_ERROR'
					) {
						store.dispatch({ type: 'LOG_OUT' })
						window.location.replace(message)
					}
				})
			}

			if (networkError) {
				console.error(`[Network error]: ${networkError}`)

				// Check if the network error message contains "invalid user" or "Unauthorized"
				if (
					networkError.message.includes('401') ||
					networkError.message.includes('invalid user') ||
					networkError.message.includes('Unauthorized')
				) {
					if (this.isAuthenticated) {
						// Only handle the error if the user is still authenticated
						this.handleUnauthorizedError()
					}
				}
			}
		})

		// @ts-ignore
		const httpLink: ApolloLink = createUploadLink({
			uri: `${API_URL}/graphql`,
			credentials: 'include',
		})

		const hasSubscriptionOperation = ({ query }: any) => {
			const definitions = getMainDefinition(query)
			return (
				definitions.kind === 'OperationDefinition' &&
				definitions.operation === 'subscription'
			)
		}

		const splitLink = split(
			hasSubscriptionOperation,
			new ActionCableLink({ cable: _cable }),
			httpLink
		)

		const client = new ApolloClient({
			link: from([authLink, errorLink, splitLink]),
			connectToDevTools: !isProductionBuild,
			cache,
			defaultOptions: {
				watchQuery: {
					fetchPolicy: 'network-only',
					errorPolicy: 'ignore',
				},
				query: {
					fetchPolicy: 'no-cache',
					errorPolicy: 'all',
				},
			},
		})
		this.client = client
		return client
	}
}

export default new ApiService()
