import React from 'react'
import { User } from 'types/api/user.model'
import { useAppDispatch } from 'store'
import { SK_AUTH_URL } from '@constants/envs'
import { API_URL } from '@constants'

import {
	ImpersonatingStatusContainer,
	ImpersonatingStatusContent,
	ImpersonatingStatusText,
	ImpersonatingStatusButton,
} from './ImpersonatingStatus.styled'
import { useAppContext } from 'modules/App/AppContext'

interface ImpersonatingStatusProps {
	user: User | null
}

const ImpersonatingStatus = ({ user }: ImpersonatingStatusProps) => {
	const dispatch = useAppDispatch()
	const { isUnifiedDashboard, refreshFeatureFlags } = useAppContext()

	const userObject = {
		email: user?.email,
		name: user?.firstName
			? `${user.firstName} ${user?.lastName || ''}`
			: user?.lastName || '',
	}

	const canAccessHub = user?.canAccessMarketingHub
	const isMarketingHubLogin = user?.isMarketingHubLogin

	const deleteCookies = async () => {
		try {
			const response = await fetch(`${API_URL}/auth/logout`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			})

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			// Parse the response JSON if needed
			const responseData = await response.json()

			// Handle the response data here
			console.log('Response:', responseData)
		} catch (error) {
			console.error('Error making POST request:', error)
		}
	}

	const handleExitImpersonation = async () => {
		await deleteCookies()
		dispatch({ type: 'LOG_OUT' })
		await refreshFeatureFlags()
		if (
			(isUnifiedDashboard || isMarketingHubLogin) &&
			(canAccessHub || user?.impersonatorUserId)
		) {
			window.location.replace(`${SK_AUTH_URL}/logout`)
		}
	}

	return (
		<ImpersonatingStatusContainer>
			<ImpersonatingStatusContent>
				<ImpersonatingStatusText>
					{`You are currently impersonating`}&nbsp;
					<strong style={{ fontWeight: '600' }}>
						{userObject?.email} ({userObject?.name})
					</strong>
					&nbsp;
					{user?.impersonatorEmail && 'as'}
					&nbsp;
					<strong style={{ fontWeight: '600' }}>
						{user?.impersonatorEmail}
					</strong>
				</ImpersonatingStatusText>

				<ImpersonatingStatusButton
					text={'Exit impersonation mode'}
					onClick={handleExitImpersonation}
				/>
			</ImpersonatingStatusContent>
		</ImpersonatingStatusContainer>
	)
}

export default ImpersonatingStatus
