import React, { lazy, useEffect, Suspense, useRef } from 'react'
import {
	Route,
	Switch,
	Redirect,
	useHistory,
	useLocation,
	matchPath,
} from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import AppLayout from 'modules/App/AppLayout'
import { useAppDispatch, useAppState } from 'store'
import { scenes, Scenes } from 'scenes/scenes'
import { breakpoints } from 'utils/styled'
import useWindowSize from 'hooks/useWindowSize'
import { SIGN_UP_STEP } from '@constants/storage'
import { useInitApp } from 'hooks/useInitApp'
import AutomatedMessagesScene from './AutomatedMessages/AutomatedMessages'
import PageLoader from 'modules/App/PageLoader'
import { AppRoute } from 'types'
import appRoutesService from 'services/appRoutes.service'
import { messagesActions } from 'store/slices/messages'
import useQuery from 'hooks/useQuery'
import authService from 'services/auth/auth.service'
import toast from 'cogo-toast'
import {
	MARKETING_URL,
	PUBLIC_URL,
	SK_LOGOUT_ROUTE,
	RS_WRITE_KEY,
	RS_DATA_PLANE_URL,
} from '@constants/envs'
import { useDispatch } from 'react-redux'
import { authActions } from 'store/slices/auth'
import { useAppContext } from 'modules/App/AppContext'
import { Chat } from 'types/api/chat.model'
import * as rudderanalytics from 'rudder-sdk-js'
import { OnboardingStages } from 'types/api/onboarding-stages.model'

const SetupProcedureScene = lazy(() => import('components/SetupProcedure'))
const SetupProcedureOptinFlowTurnOnScene = lazy(
	() => import('components/SetupProcedureOptinFlowTurnOn')
)
const SetupProcedureFirstMessageScene = lazy(
	() => import('components/SetupProcedureFirstMessage')
)
const SetupProcedureCreateContactScene = lazy(
	() => import('components/SetupProcedureCreateContact')
)
// const DashboardScene = lazy(() => import('scenes/Dashboard'))
const SignInScene = lazy(() => import('scenes/SignIn'))
const ForgotPassword = lazy(() => import('scenes/ForgotPassword'))
const CheckYourEmail = lazy(() => import('scenes/CheckYourEmail'))
const SignUpRequestScene = lazy(() => import('scenes/SignUpRequest'))
const SignUpCheckEmailScene = lazy(() => import('scenes/SignUpCheckEmail'))
const ResetPasswordScene = lazy(() => import('scenes/ResetPassword'))
const ContactSyncScene = lazy(() => import('scenes/ContactSync'))
const SignUpScene = lazy(() => import('scenes/SignUp'))
const ClientRegisterScene = lazy(() => import('scenes/ClientRegister'))
const MessagesScene = lazy(() => import('scenes/Messages'))
// const CampaignsScene = lazy(() => import('scenes/Campaigns'))
const BroadcastsScene = lazy(() => import('scenes/Broadcasts'))
const KeywordAutomationScene = lazy(() => import('scenes/KeywordAutomation'))
const ContactsScene = lazy(() => import('scenes/Contacts'))
const WelcomeOptInScene = lazy(() => import('scenes/WelcomeOptIn'))
const WelcomeOptOutScene = lazy(() => import('scenes/WelcomeOptOut'))
const WidgetsScene = lazy(() => import('scenes/Widgets'))
const SettingsScene = lazy(() => import('scenes/Settings'))
// const CampaignCreateScene = lazy(() => import('scenes/CampaignCreate'))
const CampaignViewReportScene = lazy(() => import('scenes/CampaignViewReport'))
const CampaignBroadcastCreateScene = lazy(
	() => import('scenes/CampaignBroadcastCreate')
)
const CampaignKeywordCreateScene = lazy(
	() => import('scenes/CampaignKeywordCreate')
)
const ComparePlansScene = lazy(() => import('scenes/ComparePlans'))
const PaymentConfirmationScene = lazy(
	() => import('scenes/PaymentConfirmation')
)
const CancelMembershipScene = lazy(() => import('scenes/CancelMembership'))
const GetConversationLinkRoute = lazy(
	() => import('scenes/GetConversationLink')
)

const AuthVerifyScreen = () => {
	const query = useQuery()
	const { push, replace } = useHistory()
	const dispatch = useDispatch()
	const { user: existingUser } = useAppState(state => state.auth)
	const { onboardingStages } = useAppState(state => state.onboarding)
	const { refreshFeatureFlags } = useAppContext()
	const { authorized } = useInitApp()

	useEffect(() => {
		const init = async () => {
			try {
				const code = query.get('code')

				const encodedRedirectUrl = query.get('redirect_url')

				const redirectUrl = decodeURIComponent(encodedRedirectUrl ?? '')
				messagesActions.setSelectedConversation({} as Chat)
				const routeToRedirect = redirectUrl.replace(PUBLIC_URL || '', '')

				if (!authorized) {
					appRoutesService.saveRedirectRoute({
						pathName: routeToRedirect,
						search: routeToRedirect,
					})
				}
				if (code) {
					const user = await authService.authVerify(code)

					await refreshFeatureFlags()

					if (user && existingUser) {
						if (existingUser.email != user.email) {
							dispatch({ type: 'LOG_OUT' })
						}
						dispatch(authActions.updateUser(user))
					}

					if (!user) {
						toast.error('User does not exist on convos')
						setTimeout(() => {
							!!MARKETING_URL && window.location.replace(MARKETING_URL)
						}, 1000)
					} else {
						if (onboardingStages) {
							if (!onboardingStages?.questionnaireStep) {
								replace(scenes.accountUsage.url)
							} else if (!onboardingStages?.organizationStep) {
								replace(scenes.organization.url)
							} else if (!onboardingStages?.userProfileStep) {
								replace(scenes.creatingProfile.url)
							} else if (!onboardingStages?.phoneNumberActivationStep) {
								replace(scenes.phone.url)
							} else if (routeToRedirect) {
								replace(routeToRedirect)
							} else {
								replace(scenes.mainHomeScreen.url)
							}
						} else {
							const userOrg = user?.organizations?.[0]?.id
							if (!user.questionnaireAnswers) {
								replace(scenes.accountUsage.url)
							} else if (!userOrg) {
								replace(scenes.organization.url)
							} else if (!user.firstName && !user.lastName) {
								replace(scenes.creatingProfile.url)
							} else if (userOrg && !user?.organizations?.[0]?.phone) {
								replace(scenes.phone.url)
							} else if (routeToRedirect) {
								replace(routeToRedirect)
							} else {
								replace(scenes.mainHomeScreen.url)
							}
						}
					}
				} else {
					throw new Error('No code provided')
				}
			} catch (err) {
				toast.error('Authentication failed:' + err)
				setTimeout(async () => {
					dispatch({ type: 'LOG_OUT' })
					await refreshFeatureFlags()
					!!SK_LOGOUT_ROUTE && window.location.replace(SK_LOGOUT_ROUTE)
				}, 1000)
			}
		}
		init()
	}, [])

	return <PageLoader />
}

const AuthScenes = () => {
	const { isUnifiedDashboard } = useAppContext()

	return (
		<Switch>
			<Route
				exact
				path={scenes.authenticationVerification.pattern}
				component={AuthVerifyScreen}
			/>
			<Route
				path={scenes.clientRegister.pattern}
				component={ClientRegisterScene}
			/>
			<Route exact path={scenes.signin.pattern} component={SignInScene} />
			{isUnifiedDashboard === false && (
				<Route
					exact
					path={scenes.forgotPassword.pattern}
					component={ForgotPassword}
				/>
			)}
			{isUnifiedDashboard === false && (
				<Route
					exact
					path={scenes.checkEmail.pattern}
					component={CheckYourEmail}
				/>
			)}
			{isUnifiedDashboard === true ? (
				<Route
					exact
					path={scenes.signup.pattern}
					component={ResetPasswordScene}
				/>
			) : (
				isUnifiedDashboard === false && (
					<Route
						exact
						path={[scenes.signup.pattern, scenes.resetPassword.pattern]}
						component={ResetPasswordScene}
					/>
				)
			)}
			<Route
				exact
				path={scenes.landing.pattern}
				component={SignUpRequestScene}
			/>
			{isUnifiedDashboard === false && (
				<Route
					exact
					path={scenes.checkEmail.pattern}
					component={SignUpCheckEmailScene}
				/>
			)}
			<Redirect to={scenes.signin.pattern} />
		</Switch>
	)
}

const RedirectHome = () => {
	const { width } = useWindowSize()
	const isMobile = width < breakpoints.tablet
	const to = isMobile
		? scenes.messages.url({ screen: 'primary' })
		: scenes.mainHomeScreen.url
	return <Redirect to={to} />
}
const RedirectToSetup = ({
	onboardingStages,
	isOrgOwner,
}: {
	onboardingStages: OnboardingStages
	isOrgOwner: boolean
}) => {
	let to = scenes.mainHomeScreen.url

	if (!onboardingStages?.is10dlcRegistered && isOrgOwner) {
		to = scenes.setup.url
	} else if (!onboardingStages?.contactsAdded) {
		to = scenes.setupCreateContact.url
	} else if (!onboardingStages?.isOptInFlowActive) {
		to = scenes.setupOptinFlow.url
	} else if (!onboardingStages?.textsSent) {
		to = scenes.setupFirstMessage.url
	}

	return <Redirect to={to} />
}

const RedirectInbox = () => {
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(messagesActions.setConversationListType('inbox'))
	})

	return <Redirect to={scenes.messages.url({ screen: 'primary' })} />
}

const MainScenes = () => {
	const history = useHistory()
	const { isUnifiedDashboard } = useAppContext()
	const { user } = useAppState(state => state.auth)
	const signUpStep = localStorage.getItem(SIGN_UP_STEP)
	const { brand } = useAppState(state => state.brand)
	const { cspCampaign } = useAppState(state => state.cspCampaign)
	const { onboardingStages } = useAppState(state => state?.onboarding)
	const userRole = user?.userRoles?.[0]?.role?.id

	const isOrgOwner = userRole === '1'
	const isFlowCompleted =
		onboardingStages?.is10dlcRegistered &&
		onboardingStages?.contactsAdded &&
		onboardingStages?.isOptInFlowActive &&
		onboardingStages?.textsSent

	useEffect(() => {
		if (signUpStep) history.push(scenes.organization.url)
		else {
			const redirectRoute = appRoutesService.getRedirectRoute()

			if (redirectRoute) {
				const newRoute = redirectRoute.pathName
				appRoutesService.removeRedirectRoute()
				messagesActions.setSelectedConversation({} as Chat)
				history.replace(newRoute)
			} else {
				let shouldRedirect = true
				for (const key in scenes) {
					if (Object.prototype.hasOwnProperty.call(scenes, key)) {
						const scene = scenes[key as Scenes]
						if (matchPath(history.location.pathname, scene.pattern)) {
							shouldRedirect = false
							break
						}
					}
				}
				if (shouldRedirect) {
					history.replace(scenes.mainHomeScreen.url)
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signUpStep])

	useEffect(() => {
		if (isFlowCompleted) {
			const pathName = history.location.pathname
			if (
				pathName === scenes.setup.url ||
				pathName === scenes.setupCreateContact.url ||
				pathName === scenes.setupOptinFlow.url ||
				pathName === scenes.setupFirstMessage.url
			) {
				history.replace(scenes.mainHomeScreen.url)
			}
		}
	}, [history, isFlowCompleted])

	useEffect(() => {
		if (!isOrgOwner) {
			const pathName = history.location.pathname

			if (pathName === scenes.setup.url) {
				if (onboardingStages) {
					if (!onboardingStages?.contactsAdded) {
						return history.replace(scenes.setupCreateContact.url)
					} else if (!onboardingStages?.isOptInFlowActive) {
						return history.replace(scenes.setupOptinFlow.url)
					} else if (!onboardingStages?.textsSent) {
						return history.replace(scenes.setupFirstMessage.url)
					} else {
						return history.replace(scenes.mainHomeScreen.url)
					}
				}
			}
		}
	}, [isOrgOwner, history, onboardingStages])

	useEffect(() => {
		const isAdmin =
			user &&
			user.userRoles &&
			user.userRoles[0] &&
			user.userRoles[0].role &&
			user.userRoles[0].role.name === 'organization_owner'

		if (!!onboardingStages) {
			const userOrg = user?.organizations?.[0]
			if (isAdmin) {
				if (!userOrg) {
					if (!onboardingStages?.questionnaireStep) {
						history.replace(scenes.accountUsage.url)
					} else if (!onboardingStages?.organizationStep) {
						history.replace(scenes.organization.url)
					} else if (!onboardingStages?.userProfileStep) {
						history.replace(scenes.creatingProfile.url)
					} else if (!onboardingStages?.phoneNumberActivationStep) {
						history.replace(scenes.phone.url)
					} else if (
						!onboardingStages.isOnboardingComplete &&
						!onboardingStages.is10dlcRegistered
					) {
						history.replace(scenes.setup.url)
					} else if (
						!onboardingStages.isOnboardingComplete &&
						!onboardingStages.contactsAdded
					) {
						history.replace(scenes.setupCreateContact.url)
					} else if (
						!onboardingStages.isOnboardingComplete &&
						!onboardingStages.isOptInFlowActive
					) {
						history.replace(scenes.setupOptinFlow.url)
					} else if (
						!onboardingStages.isOnboardingComplete &&
						!onboardingStages.textsSent
					) {
						history.replace(scenes.setupFirstMessage.url)
					}
				} else if (userOrg && !user?.organizations?.[0]?.phone) {
					history.replace(scenes.phone.url)
				} else if (
					!onboardingStages.isOnboardingComplete &&
					!onboardingStages.is10dlcRegistered
				) {
					history.replace(scenes.setup.url)
				} else if (
					!onboardingStages.isOnboardingComplete &&
					!onboardingStages.contactsAdded
				) {
					history.replace(scenes.setupCreateContact.url)
				} else if (
					!onboardingStages.isOnboardingComplete &&
					!onboardingStages.isOptInFlowActive
				) {
					history.replace(scenes.setupOptinFlow.url)
				} else if (
					!onboardingStages.isOnboardingComplete &&
					!onboardingStages.textsSent
				) {
					history.replace(scenes.setupFirstMessage.url)
				}
			} else {
				if (!onboardingStages?.questionnaireStep) {
					history.replace(scenes.accountUsage.url)
				} else if (!onboardingStages?.userProfileStep) {
					history.replace(scenes.creatingProfile.url)
				} else if (
					!onboardingStages.isOnboardingComplete &&
					!onboardingStages.contactsAdded
				) {
					history.replace(scenes.setupCreateContact.url)
				} else if (
					!onboardingStages.isOnboardingComplete &&
					!onboardingStages.isOptInFlowActive
				) {
					history.replace(scenes.setupOptinFlow.url)
				} else if (
					!onboardingStages.isOnboardingComplete &&
					!onboardingStages.textsSent
				) {
					history.replace(scenes.setupFirstMessage.url)
				}
			}
		}
	}, [user, onboardingStages, history.location])

	return (
		<Switch>
			<Route
				exact
				path={scenes.authenticationVerification.pattern}
				component={AuthVerifyScreen}
			/>
			<Route
				exact
				path={scenes.getConversationLink.pattern}
				component={GetConversationLinkRoute}
			/>
			<Route
				exact
				path={scenes.contactSync.pattern}
				component={ContactSyncScene}
			/>
			<Route
				exact
				path={[
					scenes.organization.pattern,
					scenes.creatingProfile.pattern,
					scenes.accountUsage.pattern,
					scenes.plan.pattern,
					scenes.payment.pattern,
					scenes.phone.pattern,
				]}
				component={SignUpScene}
			/>

			<Route
				exact
				path={[scenes.paymentPreview.pattern, scenes.paymentReview.pattern]}
				component={PaymentConfirmationScene}
			/>
			<Route
				exact
				path={scenes.cancelMembership.pattern}
				component={CancelMembershipScene}
			/>
			<Route
				exact
				path={scenes.comparePlans.pattern}
				component={ComparePlansScene}
			/>

			<AppLayout>
				<Suspense fallback={<PageLoader />}>
					<Route
						exact
						path={scenes.setup.pattern}
						component={SetupProcedureScene}
					/>
					<Route
						exact
						path={scenes.setupCreateContact.pattern}
						component={SetupProcedureCreateContactScene}
					/>
					<Route
						exact
						path={scenes.setupOptinFlow.pattern}
						component={SetupProcedureOptinFlowTurnOnScene}
					/>
					<Route
						exact
						path={scenes.setupFirstMessage.pattern}
						component={SetupProcedureFirstMessageScene}
					/>
					{/* <Route
						exact
						path={scenes.dashboard.pattern}
						component={DashboardScene}
					/> */}
					<Route exact path="/messages">
						<RedirectInbox />
					</Route>
					<Route path={scenes.messages.pattern} component={MessagesScene} />
					{/* <Route
						path={scenes.campaigns.pattern}
						exact
						component={CampaignsScene}
					/> */}
					<Route
						exact
						path={scenes.contacts.pattern}
						component={ContactsScene}
					/>
					<Route
						exact
						path={scenes.welcomeOptIn.pattern}
						component={WelcomeOptInScene}
					/>
					<Route
						exact
						path={scenes.WelcomeOptOut.pattern}
						component={WelcomeOptOutScene}
					/>
					<Route exact path={scenes.widgets.pattern} component={WidgetsScene} />
					<Route
						exact
						path={[
							scenes.settings.pattern,
							scenes.profile.pattern,
							scenes.optIn.pattern,
							scenes.myOrganization.pattern,
							scenes.myPhoneNumber.pattern,
							// scenes.billing.pattern,
							scenes.snappyKrakenConnect.pattern,
							scenes.integration.pattern,
							scenes.notifications.pattern,
						]}
						component={SettingsScene}
					/>
					{/* <Route
						exact
						path={scenes.campaignCreate.pattern}
						component={CampaignCreateScene}
					/> */}
					<Route
						path={scenes.broadcast.pattern}
						exact
						component={BroadcastsScene}
					/>
					<Route
						path={scenes.keywordAutomation.pattern}
						exact
						component={KeywordAutomationScene}
					/>
					<Route
						exact
						path={scenes.campaignBroadcastViewReport.pattern}
						component={CampaignViewReportScene}
					/>
					<Route
						exact
						path={scenes.campaignKeywordViewReport.pattern}
						component={CampaignViewReportScene}
					/>
					<Route
						exact
						path={scenes.campaignBroadcastCreate.pattern}
						component={CampaignBroadcastCreateScene}
					/>
					<Route
						exact
						path={scenes.campaignKeywordCreate.pattern}
						component={CampaignKeywordCreateScene}
					/>
					<Route
						exact
						path={scenes.automatedMessages.pattern}
						component={AutomatedMessagesScene}
					/>
					{/* 					{systemId ? (
						<Route
							exact
							path={scenes.systemNumbers.pattern}
							component={SystemNumberScene}
						/>
					) : (
						<RedirectHome />
					)} */}
					<Route exact path={scenes.signin.pattern}>
						{isFlowCompleted !== undefined && isFlowCompleted ? (
							<RedirectHome />
						) : (
							onboardingStages && (
								<RedirectToSetup
									onboardingStages={onboardingStages}
									isOrgOwner={isOrgOwner}
								/>
							)
						)}
					</Route>
					<Route exact path="/">
						{isFlowCompleted !== undefined && isFlowCompleted ? (
							<RedirectHome />
						) : (
							onboardingStages && (
								<RedirectToSetup
									onboardingStages={onboardingStages}
									isOrgOwner={isOrgOwner}
								/>
							)
						)}
					</Route>
				</Suspense>
			</AppLayout>
		</Switch>
	)
}

const CheckHistory = () => {
	const location = useLocation()
	const history = useHistory()

	const appRoutes = useRef<AppRoute[]>([])

	function getLastHttpsPart(url: string) {
		if (url.includes('https://')) {
			var parts = url.split('https://')
			var lastPart = parts.length > 1 ? parts[parts.length - 1] : ''
			return 'https://' + lastPart
		} else {
			return url
		}
	}

	useEffect(() => {
		const modifiedPathName = getLastHttpsPart(location.pathname)

		appRoutes.current = [
			{
				pathName: modifiedPathName,
				search: location.search,
			},
		]
	}, [location])

	useEffect(() => {
		window.addEventListener('beforeunload', event => {
			if (
				appRoutes.current[appRoutes.current.length - 1]?.pathName ===
					scenes.signin.url &&
				appRoutes.current[appRoutes.current.length - 2]
			) {
				appRoutesService.saveRedirectRoute(
					appRoutes.current[appRoutes.current.length - 2]
				)
			}
		})

		return () => {
			window.removeEventListener('beforeunload', () => {})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

const AppScenes = () => {
	const { authorized } = useInitApp()

	const { isUnifiedDashboard } = useAppContext()

	const { user } = useAppState(state => state.auth)

	useEffect(() => {
		rudderanalytics.load(`${RS_WRITE_KEY}`, `${RS_DATA_PLANE_URL}`)
		rudderanalytics.ready(() => {
			console.info('RS:: we are all set!!!')
		})
		if (!user) {
			rudderanalytics.reset(true)
		} else {
			rudderanalytics.identify(
				user.chargebeeCustomerId,
				{ email: user.email },
				{},
				() => {
					console.info('RS:: in identify call')
				}
			)
		}
	}, [user])

	if (typeof isUnifiedDashboard !== 'boolean') return <PageLoader />

	return (
		<BrowserRouter>
			<CheckHistory />
			{authorized ? <MainScenes /> : <AuthScenes />}
		</BrowserRouter>
	)
}

export default AppScenes
