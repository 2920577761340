import styled from 'styled-components'
import {
	NAVBAR_HEIGHT,
	SIDEBR_WIDTH,
	SIDEBR_COLLAPSED_WIDTH,
	SIDEBR_TRANSITION,
} from '@constants/layout'
import { atMobile, atSmallMobile } from '../../../utils/styled'

export const Container = styled.div`
	display: flex;
	height: 100%;
`

export const ContentContainer = styled.div<{ expanded?: boolean }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: auto;
	min-height: 100%;
	margin-left: ${({ expanded }) =>
		expanded ? SIDEBR_COLLAPSED_WIDTH : SIDEBR_WIDTH}px;
	transition: margin-left ${SIDEBR_TRANSITION};

	${atMobile(`
		margin-left: 0px;
	`)}
	background-color: ${({ theme }) => theme.colors.white};
`

export const Content = styled.div`
	flex: 1;
	margin-top: ${NAVBAR_HEIGHT}px;
	max-width: 100%;
	overflow: auto;
	${atMobile(`
		margin-bottom: 69px;
	`)}
`

export const AppContent = styled.div<{ showBanner: boolean | null }>`
	width: 100%;
	${({ showBanner }) =>
		showBanner &&
		`
		position: relative;
		padding-top: 4.125rem;
		`}
	/* padding-top: 4.125rem; */

	${({ showBanner }) =>
		showBanner &&
		atSmallMobile(`
			padding-top: 5.6875rem;
		`)}
`
