import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { Text } from 'components/Text'

export const DashboardContainer = styled.div<{ hoverColor?: string }>`
	padding: 12px 16px;
	display: flex;
	flex-direction: row;
	&:hover {
		background-color: ${props => props.hoverColor || 'transparent'};
	}
	align-items: center;
	justify-content: space-between;
`
export const DashboardRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`

export const DashboardLogo = styled(SVG)<{ isHover: boolean }>`
	width: 24px;
	height: 24.84px;

	path {
		fill: ${({ isHover }) => (isHover ? '#1B1D21' : '#60606D')};
	}
`
export const ConvosText = styled(Text)<{ isHover: boolean }>`
	font-family: Source Sans Pro;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	color: ${({ isHover }) => (isHover ? '#1B1D21' : '#60606D')};
`
