import ApiService from 'services/api'
import {
	GetMyTeamMutation,
	LoginRequest,
	LoginResponse,
	ApproveUserRequest,
	ResetPasswordRequest,
	ResetPasswordResponse,
	UpdateProfileMutation,
	UploadProfileImageResponse,
	UserCalendarLinkFieldsMutation,
	ResetPasswordFieldsMutation,
	OrderPhoneNumberMutation,
	AttachDocumentMutation,
	AssignIonLakeNumberMutation,
	GetIonLakeNumberQuery,
	GetIonLakeNumberResponse,
	GetPhoneNumbersQuery,
	PhoneNumbersQuery,
	UpdateOrganizationAddressMutation,
	VerifyBrainSessionInput,
	ToggleEmailNotificationFields,
	UpdatePersonalPhoneInput,
} from './auth.types'
import { gql } from '@apollo/client'
import { USER, LOGIN } from '../gpl/user'
import { User } from '../../types/api/user.model'
import uploadFilesService from 'services/upload-files'
import { UploadImageMutation } from 'services/upload-files'
import { Voip } from 'types/api/voip.model'
import { ORGANIZATION } from 'services/gpl/organization'
import { Organization } from 'types/api/organization.model'

export class AuthService {
	private LOG_IN = gql`
		mutation Login($email: String!, $password: String!) {
			login(input: { email: $email, password: $password }) {
				${LOGIN}
			}
		}
	`
	login = async (credentials: LoginRequest): Promise<LoginResponse> => {
		return ApiService.client
			.mutate<{ login: LoginResponse }, LoginRequest>({
				mutation: this.LOG_IN,
				variables: credentials,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data.login
			})
	}

	private AUTH_VERIFY = gql`
		mutation AuthVerify($code: String!) {
			authVerify(input: { code: $code }){
				${USER}
			}
		}
	`
	authVerify = async (code: string) =>
		ApiService.client
			.mutate<{ authVerify: User | null }>({
				mutation: this.AUTH_VERIFY,
				variables: {
					code,
				},
			})
			.then(res => {
				return res?.data?.authVerify
			})

	private LOG_IN_WITH_COOKIE = gql`
		mutation LoginWithCookie {
			loginWithCookie(input: {}) {
				${LOGIN}
			}
		}
	`
	loginWithCookie = async (): Promise<LoginResponse> => {
		return ApiService.client
			.mutate<{ loginWithCookie: LoginResponse }, {}>({
				mutation: this.LOG_IN_WITH_COOKIE,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data.loginWithCookie
			})
	}

	private APPROVE_USER = gql`
		mutation ApproveUser($input: ApproveUserInput!) {
			approveUser(input: $input) {
				id
			}
		}
	`
	approveUser = async (input: ApproveUserRequest) => {
		return ApiService.client
			.mutate<any, { input: ApproveUserRequest }>({
				mutation: this.APPROVE_USER,
				variables: {
					input: {
						email: input.email,
						skWebhookToken: 'V2VsY29tZSBTbmFwcHkgS3Jha2VuIFN0YWdpbmch',
					},
				},
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data
			})
	}

	private RESET_PASSWORD = gql`
		mutation ResetPassword(
			$password: String!
			$passwordConfirmation: String!
			$resetPasswordToken: String!
		) {
			resetPassword(
				input: {
					password: $password
					passwordConfirmation: $passwordConfirmation
					resetPasswordToken: $resetPasswordToken
				}
			) {
				${USER}
			}
		}
	`
	resetPassword = async (credentials: ResetPasswordRequest): Promise<User> => {
		return ApiService.client
			.mutate<{ resetPassword: User }, ResetPasswordRequest>({
				mutation: this.RESET_PASSWORD,
				variables: credentials,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data.resetPassword
			})
	}

	private GET_AUTH_BY_TOKEN = gql`
		query GetTokenByResetPasswordToken($token: ID!) {
			getTokenByResetPasswordToken(token: $token){
				${USER}
			}
		}
	`
	getAuthByToken = async (token: string): Promise<User> => {
		return ApiService.client
			.query<{ getTokenByResetPasswordToken: User }, { token: string }>({
				query: this.GET_AUTH_BY_TOKEN,
				variables: { token },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error(
						res.error?.message ||
							res.errors?.[0]?.message ||
							'Token is invalid or missing'
					)
				}
				return res.data.getTokenByResetPasswordToken
			})
	}
	getAuthByTokenForOnBoarding = async (token: string): Promise<User> => {
		return ApiService.client
			.query<{ getTokenByResetPasswordToken: User }, { token: string }>({
				query: this.GET_AUTH_BY_TOKEN,
				variables: { token },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error(
						res.error?.message ||
							res.errors?.[0]?.message ||
							'Token is invalid or missing'
					)
				}
				return res.data.getTokenByResetPasswordToken
			})
	}

	private GET_ME = gql`
		query ME {
			me {
				${USER}
			}
		}
	`

	getMe = async (): Promise<User> => {
		return await ApiService.client
			.query<{ me: User }>({
				query: this.GET_ME,
			})
			.then(response => {
				if (!response?.data?.me) {
					throw new Error()
				}
				return response.data.me
			})
	}

	private GET_SK_CONTACTS_COUNT = gql`
		query {
			getSkContactsCount
		}
	`

	getSkContactsCount = async (): Promise<number> => {
		return await ApiService.client
			.query<{ getSkContactsCount: number }>({
				query: this.GET_SK_CONTACTS_COUNT,
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data.getSkContactsCount
			})
	}

	private RUN_SK_CONTACT_IMPORT = gql`
		query {
			runSkContactImport {
				id
			}
		}
	`

	triggerContactSync = async (): Promise<string> => {
		return await ApiService.client
			.query<{ runSkContactImport: string }>({
				query: this.RUN_SK_CONTACT_IMPORT,
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data.runSkContactImport
			})
	}

	private UPDATE_PROFILE = gql`
		mutation UpdateUser($input: UpdateUserInput!) {
			updateUser(input: $input) {
				${USER}
			}
		}
	`
	updateProfile = async (input: UpdateProfileMutation): Promise<User> => {
		return await ApiService.client
			.mutate<{ updateUser: User }, { input: UpdateProfileMutation }>({
				mutation: this.UPDATE_PROFILE,
				variables: { input },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data.updateUser
			})
	}

	private UPLOAD_PROFILE_IMAGE = gql`
		mutation AttachUserImage($input: AttachUserImageInput!) {
			attachUserImage(input: $input) {
				clientMutationId
			}
		}
	`
	uploadProfileImage = async (
		input: UploadImageMutation & { userId?: string }
	): Promise<UploadProfileImageResponse> => {
		const { signedBlobId } = await uploadFilesService.uploadImage(input)
		const res = await ApiService.client.mutate<
			UploadProfileImageResponse,
			{ input: { userId?: string; blobId: string } }
		>({
			mutation: this.UPLOAD_PROFILE_IMAGE,
			variables: {
				input: {
					userId: input.userId,
					blobId: signedBlobId,
				},
			},
		})
		if (!res || !res.data) {
			throw new Error()
		}
		return res.data
	}

	private GET_MY_TEAM = gql`
		mutation listUsers($input: ListUsersInput!) {
			listUsers(input: $input) {
				${USER}
				}
		}
	`

	getMyTeam = async (input: GetMyTeamMutation): Promise<User[]> => {
		return await ApiService.client
			.mutate<{ listUsers: User[] }, { input: GetMyTeamMutation }>({
				mutation: this.GET_MY_TEAM,
				variables: { input },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data.listUsers
			})
	}

	private ADD_USER_CALENDAR_LINK = gql`
		mutation addUserCalendarLink($input: AddUserCalendarLinkInput!) {
			addUserCalendarLink(input: $input) {
				calendarLink
			}
		}
	`

	addUserCalendarLink = async (
		fields: UserCalendarLinkFieldsMutation
	): Promise<User> => {
		return await ApiService.client
			.mutate<
				{ addUserCalendarLink: User },
				{ input: UserCalendarLinkFieldsMutation }
			>({
				mutation: this.ADD_USER_CALENDAR_LINK,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.addUserCalendarLink
			})
	}

	private FORGOT_PASSWORD = gql`
		mutation forgotPassword($input: ForgotPasswordInput!) {
			forgotPassword(input: $input)
		}
	`

	forgotPassword = async (
		fields: ResetPasswordFieldsMutation
	): Promise<boolean> => {
		return await ApiService.client
			.mutate<{ input: ResetPasswordFieldsMutation }>({
				mutation: this.FORGOT_PASSWORD,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return true
			})
	}

	private GET_PHONE_NUMBERS = gql`
		query gePhoneNumbers($limit: Int, $areaCode: Int!) {
			getPhoneNumbers(limit: $limit, areaCode: $areaCode) {
				phoneNumber
			}
		}
	`

	getPhoneNumbers = async (
		body: GetPhoneNumbersQuery
	): Promise<PhoneNumbersQuery[]> => {
		return await ApiService.client
			.query<{ getPhoneNumbers: PhoneNumbersQuery[] }>({
				query: this.GET_PHONE_NUMBERS,
				variables: body,
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw response.errors &&
						response.errors[0] &&
						response.errors[0].message &&
						response.errors[0].message ===
							'Number listing updating, try again in 30 seconds'
						? new Error(response.errors[0].message)
						: response.errors &&
						  response.errors[0] &&
						  response.errors[0].message &&
						  response.errors[0].message ===
								'No numbers available for area code'
						? new Error(response.errors[0].message)
						: new Error()
				}
				return response.data && response.data.getPhoneNumbers
			})
	}

	private ORDER_PHONE_NUMBER = gql`
		mutation OrderPhoneNumber($input: OrderPhoneNumberInput!) {
			orderPhoneNumber(input: $input) {
				${ORGANIZATION}
			}
		}
	`

	orderPhoneNumber = async (
		fields: OrderPhoneNumberMutation
	): Promise<Organization> => {
		return await ApiService.client
			.mutate<{
				orderPhoneNumber: Organization
				input: OrderPhoneNumberMutation
			}>({
				mutation: this.ORDER_PHONE_NUMBER,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.orderPhoneNumber
			})
	}

	private GET_LIST_VOIP_SERVICES = gql`
		query {
			listVoipServices {
				id
				name
				provisioningTime
				supported
			}
		}
	`

	getListVoipServices = async (): Promise<Voip[]> => {
		return await ApiService.client
			.query<{ listVoipServices: Voip[] }>({
				query: this.GET_LIST_VOIP_SERVICES,
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.listVoipServices
			})
	}

	private ATTACH_DOCUMENT = gql`
		mutation AttachDocument($input: AttachDocumentInput!) {
			attachDocument(input: $input) {
				id
			}
		}
	`
	attachDocument = async (
		fields: AttachDocumentMutation
	): Promise<{ id: string }> => {
		return await ApiService.client
			.mutate<{
				attachDocument: { id: string }
				input: AttachDocumentMutation
			}>({
				mutation: this.ATTACH_DOCUMENT,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.attachDocument
			})
	}

	private ASSIGN_ION_LAKE_NUMBER = gql`
		mutation AssignIonLakeNumber($input: AssignIonLakeNumberInput!) {
			assignIonLakeNumber(input: $input) {
				phone
			}
		}
	`

	assignIonLakeNumber = async (
		fields: AssignIonLakeNumberMutation
	): Promise<{ phone: string; id: string }> => {
		return await ApiService.client
			.mutate<{
				assignIonLakeNumber: { phone: string; id: string }
				input: AssignIonLakeNumberMutation
			}>({
				mutation: this.ASSIGN_ION_LAKE_NUMBER,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.assignIonLakeNumber
			})
	}

	private GET_ION_LAKE_NUMBER = gql`
		query getIonLakeNumber(
			$externalApiKey: String!
			$externalPubApiKey: String!
		) {
			getIonLakeNumber(
				externalApiKey: $externalApiKey
				externalPubApiKey: $externalPubApiKey
			) {
				phoneNumber
			}
		}
	`
	getIonLakeNumber = async (
		body: GetIonLakeNumberQuery
	): Promise<GetIonLakeNumberResponse> => {
		return await ApiService.client
			.query<{ getIonLakeNumber: GetIonLakeNumberResponse }>({
				query: this.GET_ION_LAKE_NUMBER,
				variables: body,
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data && response.data.getIonLakeNumber
			})
	}

	private UPDATE_ORGANIZATION_ADDRESS = gql`
		mutation UpdateOrganizationAddress($input: UpdateOrganizationAddressInput!) {
			updateOrganizationAddress(input: $input) {
				${ORGANIZATION}
			}
		}
	`
	updateOrganizationAddress = async (
		fields: UpdateOrganizationAddressMutation
	): Promise<Organization> => {
		return await ApiService.client
			.mutate<{
				updateOrganizationAddress: Organization
				input: UpdateOrganizationAddressMutation
			}>({
				mutation: this.UPDATE_ORGANIZATION_ADDRESS,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.updateOrganizationAddress
			})
	}

	private VERIFY_BRAIN_SESSION = gql`
		mutation VerifyBrainSession($input: VerifyBrainSessionInput!) {
			verifyBrainSession(input: $input)
		}
	`
	verifyBrainSession = async (
		fields: VerifyBrainSessionInput
	): Promise<string> => {
		return await ApiService.client
			.mutate<{
				verifyBrainSession: string
				input: VerifyBrainSessionInput
			}>({
				mutation: this.VERIFY_BRAIN_SESSION,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.verifyBrainSession
			})
	}

	private TOGGLE_EMAIL_NOTIFICATION = gql`
		mutation toggleEmailNotifications($input: ToggleEmailNotificationsInput!){
			toggleEmailNotifications(input: $input){
				${USER}
			}
		}
	`
	toggleEmailNotifications = async (
		fields: ToggleEmailNotificationFields
	): Promise<User> => {
		return await ApiService.client
			.mutate<{
				toggleEmailNotifications: User
				input: ToggleEmailNotificationFields
			}>({
				mutation: this.TOGGLE_EMAIL_NOTIFICATION,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.toggleEmailNotifications
			})
	}

	private UPDATE_PERSONAL_PHONE = gql`
		mutation updatePersonalPhone($input: UpdatePersonalPhoneInput!){
			updatePersonalPhone(input: $input){
				${USER}
			}
		}
	`
	updatePersonalPhone = async (
		fields: UpdatePersonalPhoneInput
	): Promise<User> => {
		return await ApiService.client
			.mutate<{
				updatePersonalPhone: User
				input: UpdatePersonalPhoneInput
			}>({
				mutation: this.UPDATE_PERSONAL_PHONE,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.updatePersonalPhone
			})
	}
}

export default new AuthService()
