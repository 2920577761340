import React from 'react'
import { StyledCircle, StyledSvg } from './Spin.styled'

const Spin = ({
	style = {},
	margin,
}: {
	style?: React.CSSProperties
	margin?: string
}) => {
	return (
		<StyledSvg style={style} viewBox="0 0 44 44" margin={margin}>
			<StyledCircle
				cx="22"
				cy="22"
				r="20"
				fill="none"
				strokeWidth="4"
			></StyledCircle>
		</StyledSvg>
	)
}

export default Spin
