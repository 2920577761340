import {
	NAVBAR_HEIGHT,
	SIDEBR_WIDTH,
	SIDEBR_COLLAPSED_WIDTH,
	SIDEBR_TRANSITION,
} from '@constants/layout'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { atMobile } from 'utils/styled'
import { Input } from 'components/Input'
import { Text } from 'components/Text'
import { Divider } from 'antd'

export const Container = styled.div<{ expanded: boolean }>`
	align-items: center;
	background-color: ${p => p.theme.colors.white};
	border-bottom: 1px solid ${p => p.theme.colors.border};
	display: flex;
	height: ${NAVBAR_HEIGHT}px;
	justify-content: space-between;
	padding: 0 1.875rem;
	position: fixed;
	transition: width ${SIDEBR_TRANSITION};
	width: calc(100% - ${SIDEBR_WIDTH}px);
	z-index: 101;

	${({ expanded }) =>
		expanded && `width: calc(100% - ${SIDEBR_COLLAPSED_WIDTH}px);`}

	${atMobile(`
		width: 100%;
	`)}
`

export const AppHeaderSearchBarContainer = styled.div`
	margin: 0 2rem;
	position: relative;
	min-width: 300px;
	width: 28rem;

	input {
		padding-left: 10px;
	}

	${atMobile(`
		display: none
	`)}
`

export const AppHeaderSearchBarInput = styled(Input)`
	border-radius: 2.125rem;
	border: 1px solid #eff0f0;
	height: 2.125rem;
	padding: 0px 1rem;

	input {
		font-size: 0.75rem;
	}

	input::placeholder {
		font-weight: 600;
		overflow: visible;
	}
`

export const AppHeaderSearchBarMessagesSelect = styled.div`
	position: absolute;
	top: 40px;
	background: white;
	width: 100%;
	border-radius: 10px;
	padding: 16px 5px 16px 0;
	border: 1px solid #dadada;
	max-height: 420px;
	overflow-y: scroll;
	box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.15);

	&::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	&::-webkit-scrollbar:vertical {
		width: 5px;
	}

	&::-webkit-scrollbar-button:increment,
	&::-webkit-scrollbar-button {
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #797979;
		border-radius: 20px;
		border: 0px;
		height: 10px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track-piece:end,
	&::-webkit-scrollbar-track-piece:start {
		background: transparent;
	}
`
export const AppHeaderSearchBarMessagesSelectDescription = styled(Text)`
	padding: 0 2rem;
	font-size: 0.75rem;
	line-height: 0.75rem;
	color: ${({ theme }) => theme.colors.fontSecondary};
	strong {
		font-weight: 600;
	}
`
export const AppHeaderSearchBarMessagesSelectOption = styled.div`
	padding: 5px 15px 5px 15px;
	font-size: 1rem;
	/* cursor: pointer; */
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.fontMain};
	&:hover {
		background-color: #fafafa;
	}
	svg {
		margin-right: 6px;
		margin-top: 2px;
		path {
			fill: ${({ theme }) => theme.colors.fontMain};
		}
	}
`

export const ConvosContainer = styled.div`
	padding: 12px 16px;
	background: ${({ theme }) => theme.colors.greyMain};
`

export const ConvosIconContainer = styled.div`
	padding: 8px 0px;
`

export const ConvosTextSimple = styled(Text)`
	//styleName: Text M/600;
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: 600;
	color: #ffffff;
`

export const AppHeaderSearchBarMessagesSelectOptionSeparator = styled(Text)`
	font-size: 0.75rem;
	margin-right: 5px;
	margin-left: 5px;
`
export const AppHeaderSearchBarMessagesSelectOptionContact = styled(Text)`
	font-size: 0.75rem;
	background-color: ${({ theme }) => theme.colors.accentPrimaryOpacity};
	padding: 2px 5px 3px;
	border-radius: 6px;
	strong {
		font-weight: 600;
	}
`
export const AppHeaderSearchBarMessagesSelectOptionMessage = styled(Text)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${({ theme }) => theme.colors.fontMain};
`

export const AppHeaderUserContainer = styled.div`
	align-items: center;
	display: flex;
`

export const AppHeaderUserMenuContainer = styled.div`
	cursor: pointer;
`

export const DashboardMenuSvg = styled.img`
	margin-right: 12px;
`

export const AppHeaderUserMenuAvatar = styled.div`
	align-items: center;
	background: linear-gradient(#bdbbbc, #8b8687);
	box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	color: white;
	display: flex;
	font-size: 0.875rem;
	font-weight: 600;
	height: 2.125rem;
	justify-content: center;
	overflow: hidden;
	width: 2.125rem;

	img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
`

export const AvatarRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.white};
	padding: 0px 16px 20px 16px;
`

export const MenuDivider = styled(Divider)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	background-color: ${({ theme }) => theme.colors.border};
	margin: 0px;
`

export const MenuRow = styled.div`
	display: flex;
`

export const PolygonSvg = styled.img`
	border: 1px solid ${({ theme }) => theme.colors.border};
`

export const MenuSvg = styled.img``

export const UserImage = styled.div`
	align-items: center;
	background: linear-gradient(#bdbbbc, #8b8687);
	box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
	border-radius: 100px;
	color: white;
	display: flex;
	font-size: 0.875rem;
	font-weight: 600;
	height: 48px;
	justify-content: center;
	overflow: hidden;
	width: 48px;
	img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
`

export const UserImageDummy = styled.div`
	align-items: center;
	background: transparent;
	border-radius: 100px;
	color: white;
	display: flex;
	font-size: 0.875rem;
	font-weight: 600;
	height: 28px;
	justify-content: center;
	overflow: hidden;
	width: 28px;
`

export const AppHeaderUserDropDown = styled.div<{ open: boolean }>`
	display: none;
	background-color: ${({ theme }) => theme.colors.white};
	z-index: 99999;
	position: absolute;
	right: 282px;
	top: 65px;
	${({ open }) =>
		open &&
		`
		display: block;
	`}
`

export const InfoContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 6px;
	margin-top: 25px;
`

export const UserName = styled(Text)`
	font-family: Source Sans Pro;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -0.02px;
`

export const UserEmail = styled(Text)`
	color: var(--grey-600, #60606d);
	font-family: Source Sans Pro;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -0.07px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 175px;
`
export const SettingsLink = styled.span`
	color: ${({ theme }) => theme.colors.focus};
	font-size: 0.875rem;
	line-height: 0.875rem;
	display: flex;
	align-items: center;
	transition: all 0.2s ease-in-out;
	justify-self: flex-start;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	letter-spacing: -0.02em;
	margin-top: 8px;
`

export const FormControlCopy = styled.span`
	color: ${({ theme }) => theme.colors.focus};
	font-size: 0.875rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 0.5rem 0 0;
	transition: all 0.2s ease-in-out;
	justify-self: flex-start;
`

export const OrgContainer = styled.div`
	padding: 16px;
	cursor: auto;
`

export const MainOrgName = styled(Text)`
	color: var(--grey-main, #1b1d21);
	font-family: Source Sans Pro;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	letter-spacing: -0.24px;
`

export const ParentOrgName = styled(Text)`
	color: var(--grey-500, #808191);
	font-family: Source Sans Pro;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: -0.07px;
`

export const Billing = styled(Text)`
	color: var(--grey-600, #60606d);
	font-family: Source Sans Pro;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -0.07px;
	margin-bottom: 5px;
	margin-bottom: 20px;
`

export const Optin = styled(Text)`
	color: var(--grey-600, #60606d);
	font-family: Source Sans Pro;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -0.07px;
`

export const MenuFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 16px 16px 22px 16px;
`

export const Signout = styled(Text)`
	color: var(--grey-main, #1b1d21);
	font-family: Source Sans Pro;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	letter-spacing: -0.21px;
`

export const Privacy = styled(Text)`
	color: var(--grey-main, #1b1d21);
	font-family: Source Sans Pro;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	letter-spacing: -0.21px;
`

export const AppHeaderUserDropDownList = styled.div<{
	open: boolean
	width: number
}>`
	display: flex;
	width: ${({ width }) => width}px;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 16px;
	overflow: auto;
	position: absolute;
	opacity: 0;
	transition: opacity 0.12s ease-out;
	${({ open }) =>
		open &&
		`
		opacity: 1;
	`}
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`

export const AppHeaderUserDropDownSelectOption = styled.div<{ open: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px;
	background-color: transparent;
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

export const AppHeaderDivider = styled(Divider)`
	border-left-width: 1px;
	border-color: ${({ theme }) => theme.colors.border};
	height: 1rem;
	margin: 0 0.875rem;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
`
