import { createSelector, createSlice } from '@reduxjs/toolkit'
import { IStoreState } from 'store'
import toast from 'cogo-toast'
import { IContactGroup, IContactTag } from 'types/api/contact'
import createAsyncReducers from 'utils/create-async-reducers'
import { Contact } from 'types/api/contact.model'
import {
	createContact,
	updateContact,
	createContactGroup,
	updateContactGroup,
	createContactTag,
	updateContactTag,
	getContact,
	verifyDuplicateContact,
	getContacts,
	getContactsInCampaign,
	getTagsContact,
	getGroupContact,
	deleteContact,
	deleteContactGroup,
	deleteContactTag,
	addContactGroup,
	listContacts,
	bulkUploadContacts,
	getNumBulkContacts,
	searchContacts,
	getContactsCount,
	getLatestContact,
	getOutboundSmsCount,
} from './contact.actions'
import {
	BulkUploadContactsResponse,
	GetNumBulkContactsResponse,
} from 'services/contact'
import { ContactList } from 'types/api/contacts-list'

interface IState {
	contact?: Contact | null
	contactCreated?: Contact
	contactList?: ContactList
	campaignContactList?: ContactList
	allContactsList?: ContactList
	contactLNCList?: ContactList
	groupList?: IContactGroup[]
	tagList?: IContactTag[]
	bulkUploadDataResult?: BulkUploadContactsResponse | null
	numBulkContactsResult?: GetNumBulkContactsResponse | null
	focusContactGroup: boolean
	newModal: boolean
	editModal: boolean
	loading: boolean
	loadingContacts: boolean
	contactSearchLoading: boolean
	contactsCount?: number
	loadingContactsCount?: boolean
	loadingLatestContact?: boolean
}

const initialState: IState = {
	newModal: false,
	editModal: false,
	loading: false,
	focusContactGroup: false,
	loadingContacts: false,
	allContactsList: {
		contacts: [],
		totalContacts: 0,
	},
	contactSearchLoading: false,
	contactsCount: 0,
	loadingContactsCount: false,
	loadingLatestContact: false,
}

const { reducer, actions } = createSlice({
	name: 'contact',
	initialState,
	reducers: {
		newContactModal(state) {
			state.newModal = !state.newModal
		},
		editContactModal(state) {
			state.editModal = !state.editModal
		},
		focusContactGroup(state) {
			state.focusContactGroup = !state.focusContactGroup
		},
		clearContactCreated(state) {
			state.contactCreated = undefined
		},
	},
	extraReducers: builder => {
		createAsyncReducers(builder, getContact, 'loading', (state, action) => {
			state.contact = action.payload
		})
		createAsyncReducers(builder, verifyDuplicateContact, 'loading')
		createAsyncReducers(
			builder,
			getContacts,
			'loadingContacts',
			(state, action) => {
				state.contactList = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getContactsInCampaign,
			'loadingContacts',
			(state, action) => {
				state.campaignContactList = action.payload
			}
		)
		createAsyncReducers(builder, listContacts, 'loading', (state, action) => {
			state.contactLNCList = action.payload
		})
		createAsyncReducers(builder, addContactGroup, 'loading')
		createAsyncReducers(builder, getTagsContact, 'loading', (state, action) => {
			state.tagList = action.payload
		})
		createAsyncReducers(
			builder,
			getGroupContact,
			'loading',
			(state, action) => {
				state.groupList = action.payload
			}
		)
		createAsyncReducers(builder, createContact, 'loading', (state, action) => {
			const contactList = state.contactList
				? {
						contacts: [...state.contactList?.contacts, action.payload],
						totalContacts: state.contactList?.totalContacts + 1,
				  }
				: { contacts: [action.payload], totalContacts: 1 }
			state.contactList = contactList
			state.contactCreated = action.payload
			toast.success('The contact was created successfully', {
				position: 'top-right',
			})
		})
		createAsyncReducers(builder, updateContact, 'loading', (state, action) => {
			const contactList = state.contactList
				? {
						contacts: state.contactList?.contacts.map(contact =>
							contact.id === action.payload.id ? action.payload : contact
						),
						totalContacts: state.contactList.totalContacts,
				  }
				: { contacts: [action.payload], totalContacts: 1 }
			state.contactList = contactList
			state.editModal = !state.editModal
			toast.success('The contact was updated successfully', {
				position: 'top-right',
			})
		})
		createAsyncReducers(
			builder,
			searchContacts,
			'contactSearchLoading',
			(state, action) => {
				state.allContactsList = {
					contacts: action.payload.contacts,
					totalContacts: action.payload.totalContacts,
				}
			}
		)
		createAsyncReducers(
			builder,
			createContactGroup,
			'loading',
			(state, action) => {
				const groupList = state.groupList
					? [...state.groupList, action.payload]
					: [action.payload]
				state.groupList = groupList
				toast.success('The contact group was created successfully', {
					position: 'top-right',
				})
			}
		)
		createAsyncReducers(
			builder,
			updateContactGroup,
			'loading',
			(state, action) => {
				state.groupList =
					state.groupList &&
					state.groupList.map(group => {
						if (group.id === action.payload.id) {
							return action.payload
						}
						return group
					})
				toast.success('The contact group was updated successfully', {
					position: 'top-right',
				})
			}
		)
		createAsyncReducers(
			builder,
			createContactTag,
			'loading',
			(state, action) => {
				const tagList = state.tagList
					? [...state.tagList, action.payload]
					: [action.payload]
				state.tagList = tagList
				toast.success('The Tag was created successfully', {
					position: 'top-right',
				})
			}
		)
		createAsyncReducers(
			builder,
			updateContactTag,
			'loading',
			(state, action) => {
				state.tagList =
					state.tagList &&
					state.tagList.map(tag => {
						if (tag.id === action.payload.id) {
							return action.payload
						}
						return tag
					})
				toast.success('The Tag was updated successfully', {
					position: 'top-right',
				})
			}
		)
		createAsyncReducers(builder, deleteContact, 'loading')
		createAsyncReducers(
			builder,
			deleteContactTag,
			'loading',
			(state, action) => {
				const tagList = state.tagList
					? state.tagList.filter(tag => tag.id !== action.payload.id)
					: []
				state.tagList = tagList
				contactActions.getTagsContact()
				toast.success('The Tag has been deleted successfully', {
					position: 'top-right',
				})
			}
		)
		createAsyncReducers(
			builder,
			deleteContactGroup,
			'loading',
			(state, action) => {
				const groupList = state.groupList
					? state.groupList.filter(group => group.id !== action.payload.id)
					: []
				state.groupList = groupList
				toast.success('The contact group  has been deleted successfully', {
					position: 'top-right',
				})
			}
		)
		createAsyncReducers(
			builder,
			bulkUploadContacts,
			'loading',
			(state, action) => {
				state.bulkUploadDataResult = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getNumBulkContacts,
			'loading',
			(state, action) => {
				state.numBulkContactsResult = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getContactsCount,
			'loadingContactsCount',
			(state, action) => {
				state.contactsCount = action.payload
			}
		)
		createAsyncReducers(builder, getLatestContact, 'loadingLatestContact')
		createAsyncReducers(builder, getOutboundSmsCount, 'loadingLatestContact')
	},
})

export default reducer

const selectContactState = (state: IStoreState) => state.contact

const selectModalContact = createSelector(selectContactState, state => state)

export const contactSelectors = {
	selectModalContact,
}

export const contactActions = {
	...actions,
	createContact,
	addContactGroup,
	updateContactGroup,
	updateContact,
	updateContactTag,
	listContacts,
	createContactGroup,
	createContactTag,
	getContact,
	verifyDuplicateContact,
	getContacts,
	getContactsInCampaign,
	getTagsContact,
	getGroupContact,
	deleteContact,
	deleteContactGroup,
	deleteContactTag,
	bulkUploadContacts,
	getNumBulkContacts,
	searchContacts,
	getContactsCount,
	getLatestContact,
	getOutboundSmsCount,
}
