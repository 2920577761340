import { gql } from '@apollo/client'
import ApiService from 'services/api'
import {
	Campaign,
	Template,
	CampaignConversation,
	CampaignOptinFlowMessage,
	CampaignOptinFlowInboundMessage,
	CampaignBroadcastResponse,
} from 'types/api/campaign.model'
import { CampaignMetrics } from 'types/api/campaign-metrics.model'
import {
	CAMPAIGN,
	CAMPAIGN_LIST,
	CAMPAIGN_BASIC,
	CAMPAIGN_CONVERSATIONS,
	CAMPAIGN_OPTIN_FLOW_MESSAGE,
	TEMPLATE,
	GOAL,
	CAMPAIGN_INBOUND_OPTIN_FLOW_MESSAGE,
	CAMPAIGN_REPORT,
} from './campaign.gpl'
// Models
import {
	CampaignFieldsQuery,
	CampaignConversationsQuery,
	CampaignCreateFields,
	CampaignListFields,
	UpdateCreateFields,
	CreateCampaignTemplateFields,
	CampaingSend,
	CampaignSendFieldsMutation,
	AttachCampaignImage,
	AttachCampaignImageFieldsMutation,
	CampaignMetricsQuery,
	DuplicateCampaignMutation,
	CampaignRecipientMutation,
	CampaignConversationQuery,
	CampaignBroadcastResponsesQuery,
	CampaignCreateOptinFlowMessageFields,
	CampaignUpdateOptinFlowMessageFields,
	StartOptinFlowCampaign,
	ToggleOptinFlowCampaign,
	OptInContactCampaign,
	ListTemplatesQuery,
	ListGoalsQuery,
	CampaignCreateKeywordFields,
	CampaignDeleteOptinFlowMessageFields,
	CreateKeywordCampaignTemplateFields,
	ArchiveFields,
} from './campaign.types'
import { CAMPAIGN_METRICS } from 'services/gpl/campaign-metrics'
import { ORGANIZATION } from 'services/gpl/organization'
import { Contact } from 'types/api/contact.model'

import { CONTACT } from 'services/gpl/contact'

import { Organization } from 'types/api/organization.model'
import { IGoal } from '../../types/api/campaign'
import { CONVERSATIONS_COMBINED, MESSAGE } from 'services/messages/messages.gpl'
import { ConversationList } from 'types/api/message.model'

class CampaignService {
	private CREATE_CAMPAIGN = gql`
		mutation saveCampaign($input: SaveCampaignInput!){
			saveCampaign(input: $input){
        		${CAMPAIGN}
      		}
		}
	`

	private CREATE_CAMPAIGN_RECIPIENT = gql`
		mutation createCampaignRecipient($input: CreateCampaignRecipientInput!){
      		createCampaignRecipient(input: $input){
        		${CAMPAIGN}
      		}
		}
	`

	private UPDATE_CAMPAIGN = gql`
		mutation updateCampaign($input: UpdateCampaignInput!){
			updateCampaign(input: $input){
        		${CAMPAIGN}
      		}
		}
	`

	private ARCHIVE_CAMPAIGN = gql`
		mutation archiveCampaign($input: ArchiveCampaignInput!){
			archiveCampaign(input: $input){
        		${CAMPAIGN}
      		}
		}
	`

	private POLL_CAMPAIGN = gql`
		subscription {
			pollCampaign {
				response {
					${CAMPAIGN_BASIC}
				}
			}
		}
	`

	private GET_CAMPAIGN = gql`
		query getCampaign ($id: ID!) {
			getCampaign (id: $id) {
				${CAMPAIGN_REPORT}
			}
	}`

	private GET_CAMPAIGNS = gql`
		query getCampaigns ($limit: Int, $offset: Int, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
			listCampaigns (limit: $limit, offset: $offset) {
				${CAMPAIGN_LIST}
				messagesReceived(startDate: $startDate, endDate: $endDate)
				messagesSent(startDate: $startDate, endDate: $endDate)
			}
	}`

	private GET_BROADCAST_CAMPAIGNS = gql`
		query getBroadcastCampaigns ($limit: Int, $offset: Int, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
			listBroadcastCampaigns (limit: $limit, offset: $offset) {
				${CAMPAIGN_LIST}
				messagesReceived(startDate: $startDate, endDate: $endDate)
				messagesSent(startDate: $startDate, endDate: $endDate)
			}
	}`
	private GET_KEYWORD_CAMPAIGNS = gql`
		query getKeywordCampaigns ($limit: Int, $offset: Int, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
			listKeywordCampaigns (limit: $limit, offset: $offset) {
				${CAMPAIGN_LIST}
				messagesReceived(startDate: $startDate, endDate: $endDate)
				messagesSent(startDate: $startDate, endDate: $endDate)
			}
	}`

	private GET_CAMPAIGNS_COLLECTIONS = gql`
		query getCampaigns ($leadsNeverCold: Boolean) {
			listCampaigns (leadsNeverCold: $leadsNeverCold) {
				${CAMPAIGN_LIST}
			}
	}`

	private SEND_CAMPAIGNS = gql`
		mutation SendCampaign($input: SendCampaignInput!) {
			sendCampaign(input: $input) {
				id
			}
		}
	`

	private ATTACH_CAMPAIGN_IMAGE = gql`
		mutation AttachCampaignImage($input: AttachCampaignImageInput!) {
			attachCampaignImage(input: $input) {
				campaign {
					id
				}
			}
		}
	`

	private FETCH_CAMPAIGN_CONVERSATIONS = gql`
		query fetchCampaignConversations($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
			fetchCampaignConversations(startDate: $startDate, endDate: $endDate) {
				${CAMPAIGN_CONVERSATIONS}
			}
		}
	`

	private LIST_CAMPAIGN_CONVERSATIONS = gql`
		query listCampaignConversations($limit: Int, $offset: Int) {
			listCampaignConversations(limit: $limit, offset: $offset) {
				${CONVERSATIONS_COMBINED}
			}
			totalCampaignConversations
		}
	`

	private GET_CAMPAIGN_CONVERSATION = gql`
		query getCampaignConversation($id: ID!) {
			getCampaignConversation(id: $id) {
				${CAMPAIGN_CONVERSATIONS}
			}
		}
	`

	private GET_BROADCAST_RESPONSES = gql`
		query listBroadcastResponses($campaignId: ID!, $limit: Int, $offset: Int) {
			listBroadcastResponses(
				campaignId: $campaignId
				limit: $limit
				offset: $offset
			) {
				messages {
					${MESSAGE}
				}
				totalResponses
			}
		}
	`

	private GET_CAMPAIGN_OPTIN_FLOW_OUTBOUND_MESSAGES = gql`
		query ListOptinFlowMessages {
			listOptinFlowMessages {
					${CAMPAIGN_OPTIN_FLOW_MESSAGE}
				}
		}
	`
	private CREATE_CAMPAIGN_OPTIN_FLOW_OUTBOUND_MESSAGES = gql`
		mutation createOptinFlowMessage($input: CreateOptinFlowMessageInput!){
			createOptinFlowMessage(input: $input){
				${CAMPAIGN_OPTIN_FLOW_MESSAGE}
			}
		}
	`
	private UPDATE_CAMPAIGN_OPTIN_FLOW_OUTBOUND_MESSAGES = gql`
		mutation updateOptinFlowMessage($input: UpdateOptinFlowMessageInput!){
			updateOptinFlowMessage(input: $input){
				${CAMPAIGN_OPTIN_FLOW_MESSAGE}
			}
		}
	`
	private DELETE_CAMPAIGN_OPTIN_FLOW_OUTBOUND_MESSAGES = gql`
		mutation deleteOptinFlowMessage($input: DeleteOptinFlowMessageInput!){
			deleteOptinFlowMessage(input: $input){
				${CAMPAIGN_OPTIN_FLOW_MESSAGE}
			}
		}
	`

	private GET_CAMPAIGN_OPTIN_FLOW_INBOUND_MESSAGES = gql`
		query ListOrgOptinFlowMessages {
			listOrgOptinFlowMessages {
					${CAMPAIGN_INBOUND_OPTIN_FLOW_MESSAGE}
				}
		}
	`
	private CREATE_CAMPAIGN_OPTIN_FLOW_INBOUND_MESSAGES = gql`
		mutation createOrgOptinFlowMessage($input: CreateOrgOptinFlowMessageInput!){
			createOrgOptinFlowMessage(input: $input){
				${CAMPAIGN_INBOUND_OPTIN_FLOW_MESSAGE}
			}
		}
	`
	private UPDATE_CAMPAIGN_OPTIN_FLOW_INBOUND_MESSAGES = gql`
		mutation updateOrgOptinFlowMessage($input: UpdateOrgOptinFlowMessageInput!){
			updateOrgOptinFlowMessage(input: $input){
				${CAMPAIGN_INBOUND_OPTIN_FLOW_MESSAGE}
			}
		}
	`
	private DELETE_CAMPAIGN_OPTIN_FLOW_INBOUND_MESSAGES = gql`
		mutation deleteOptinFlowMessage($input: DeleteOrgOptinFlowMessageInput!){
			deleteOrgOptinFlowMessage(input: $input){
				${CAMPAIGN_INBOUND_OPTIN_FLOW_MESSAGE}
			}
		}
	`
	private START_OPTIN_FLOW_MESSAGE_CAMPAIGN = gql`
		mutation startOptinFlow($input: StartOptinFlowInput!){
			startOptinFlow(input: $input){
				${CONTACT}
			}
		}
	`

	private OPTIN_CONTACT_CAMPAIGN = gql`
		mutation optInContact($input: OptInContactInput!){
			optInContact(input: $input){
				${CONTACT}
			}
		}
	`

	private FETCH_CAMPAIGN_METRICS = gql`
		query fetchCampaignMetrics(
			$startDate: ISO8601DateTime!
			$endDate: ISO8601DateTime!
			$campaignId: ID!
		) {
			fetchCampaignMetrics(
				startDate: $startDate
				endDate: $endDate
				campaignId: $campaignId
			) {
				messagesSent
				messagesReceived
				responseRate
				optOuts
				optOutRate
				linksClicked
				linksClickedRate
				appointmentsBooked
				hasLinks
				optedOutContacts {
					${CONTACT}
				}
			}
		}
	`

	private DUPLICATE_CAMPAIGNS = gql`
		mutation duplicateCampaign($input: DuplicateCampaignInput!) {
			duplicateCampaign(input: $input) {
				id
			}
		}
	`

	private LIST_TEMPLATES = gql`
		query listTemplates($limit: Int, $offset: Int, $query: String, $archived: Boolean, $sortByFeatured: Boolean) {
			listTemplates(limit: $limit, offset: $offset, query: $query, archived: $archived, sortByFeatured: $sortByFeatured) {
				${TEMPLATE}
			}
		}
	`

	private LIST_GOALS = gql`
		query listGoals($name: String) {
			listGoals(name: $name) {
				${GOAL}
			}
		}
	`

	private CREATE_CAMPAIGN_TEMPLATE = gql`
		mutation createCampaignTemplate($input: CreateCampaignTemplateInput!){
			createCampaignTemplate(input: $input){
				${TEMPLATE}
			}
		}
	`

	private CREATE_KEYWORD_CAMPAIGN_TEMPLATE = gql`
		mutation createKeywordCampaignTemplate($input: CreateKeywordCampaignTemplateInput!){
			createKeywordCampaignTemplate(input: $input){
				${TEMPLATE}
			}
		}
	`

	private CREATE_KEYWORD_CAMPAIGN = gql`
		mutation saveKeyword(
			$id: ID!
			$name: String!
			$status: String!
			$keyword: String!
			$autoResponses: [AutoResponseInput!]
		) {
			saveKeywordCampaign(
				input: {
					id: $id
					name: $name
					status: $status
					keyword: $keyword
					autoResponses: $autoResponses
				}
			) {
				${CAMPAIGN}
			}
		}
	`

	private NEW_KEYWORD_CAMPAIGN = gql`
		mutation saveKeyword(
			$name: String!
			$status: String!
			$keyword: String!
			$autoResponses: [AutoResponseInput!]
		) {
			saveKeywordCampaign(
				input: {
					name: $name
					status: $status
					keyword: $keyword
					autoResponses: $autoResponses
				}
			) {
				${CAMPAIGN}
			}
		}
	`

	pollCampaign = (next: (data: { campaign: Campaign }) => void) => {
		return ApiService.client
			.subscribe({
				query: this.POLL_CAMPAIGN,
			})
			.subscribe({
				next: ({ data }) => {
					if (data && data.pollCampaign && data.pollCampaign.response) {
						next({ campaign: data.pollCampaign.response })
					}
				},
				error: e => console.error('[Error] Polling campaign: ', e),
			})
	}

	createCampaign = async (fields: CampaignCreateFields): Promise<Campaign> => {
		return await ApiService.client
			.mutate<{ saveCampaign: Campaign }, { input: CampaignCreateFields }>({
				mutation: this.CREATE_CAMPAIGN,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.saveCampaign
			})
	}

	createKeyWordCampaign = async (
		fields: CampaignCreateKeywordFields
	): Promise<Campaign> => {
		return await ApiService.client
			.mutate<{ saveKeywordCampaign: Campaign }, CampaignCreateKeywordFields>({
				mutation: this.CREATE_KEYWORD_CAMPAIGN,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.saveKeywordCampaign
			})
	}

	newKeywordCampaign = async (
		fields: CampaignCreateKeywordFields
	): Promise<Campaign> => {
		return await ApiService.client
			.mutate<{ saveKeywordCampaign: Campaign }, CampaignCreateKeywordFields>({
				mutation: this.NEW_KEYWORD_CAMPAIGN,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.saveKeywordCampaign
			})
	}

	createCampaignRecipient = async (
		fields: CampaignRecipientMutation
	): Promise<Campaign> => {
		return await ApiService.client
			.mutate<
				{ createCampaignRecipient: Campaign },
				{ input: CampaignRecipientMutation }
			>({
				mutation: this.CREATE_CAMPAIGN_RECIPIENT,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createCampaignRecipient
			})
	}

	updateCampaign = async (fields: UpdateCreateFields): Promise<Campaign> => {
		return await ApiService.client
			.mutate<{ createCampaign: Campaign }, { input: UpdateCreateFields }>({
				mutation: this.UPDATE_CAMPAIGN,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createCampaign
			})
	}

	getCampaign = async (id: string = ''): Promise<Campaign> => {
		return await ApiService.client
			.query({
				query: this.GET_CAMPAIGN,
				variables: { id },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.getCampaign
			})
	}

	getCampaigns = async (fields: CampaignFieldsQuery): Promise<Campaign[]> => {
		return await ApiService.client
			.query({
				query: this.GET_CAMPAIGNS,
				variables: {
					limit: fields.limit,
					endDate: fields.endDate,
					startDate: fields.startDate,
					...fields,
				},
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.listCampaigns
			})
	}
	getBroadcastCampaigns = async (
		fields: CampaignFieldsQuery
	): Promise<Campaign[]> => {
		return await ApiService.client
			.query({
				query: this.GET_BROADCAST_CAMPAIGNS,
				variables: {
					limit: fields.limit,
					endDate: fields.endDate,
					startDate: fields.startDate,
					...fields,
				},
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.listBroadcastCampaigns
			})
	}
	getKeywordCampaigns = async (
		fields: CampaignFieldsQuery
	): Promise<Campaign[]> => {
		return await ApiService.client
			.query({
				query: this.GET_KEYWORD_CAMPAIGNS,
				variables: {
					limit: fields.limit,
					endDate: fields.endDate,
					startDate: fields.startDate,
					...fields,
				},
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.listKeywordCampaigns
			})
	}

	getcollectionCampaigns = async (
		leadsNeverCold: boolean = true
	): Promise<Campaign[]> => {
		return await ApiService.client
			.query({
				query: this.GET_CAMPAIGNS_COLLECTIONS,
				variables: { leadsNeverCold },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.listCampaigns
			})
	}

	sendCampaign = async (
		fields: CampaignSendFieldsMutation
	): Promise<CampaingSend> => {
		return await ApiService.client
			.mutate<
				{ sendCampaign: CampaingSend },
				{ input: CampaignSendFieldsMutation }
			>({
				mutation: this.SEND_CAMPAIGNS,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.sendCampaign
			})
	}

	attachCampaignImage = async (
		fields: AttachCampaignImageFieldsMutation
	): Promise<AttachCampaignImage> => {
		return await ApiService.client
			.mutate<
				{ attachCampaignImage: AttachCampaignImage },
				{ input: AttachCampaignImageFieldsMutation }
			>({
				mutation: this.ATTACH_CAMPAIGN_IMAGE,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data!.attachCampaignImage
			})
	}

	getCampaignMetrics = async (
		fields: CampaignMetricsQuery
	): Promise<CampaignMetrics> => {
		return await ApiService.client
			.query<{
				fetchCampaignMetrics: CampaignMetrics
				input: CampaignMetricsQuery
			}>({
				query: this.FETCH_CAMPAIGN_METRICS,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || response.error || !response.data) {
					throw new Error()
				}
				return response.data && response.data.fetchCampaignMetrics
			})
	}

	getCampaignConversations = async (
		fields: CampaignConversationsQuery
	): Promise<CampaignConversation[]> => {
		return await ApiService.client
			.query<{
				fetchCampaignConversations: CampaignConversation[]
				input: CampaignConversationsQuery
			}>({
				query: this.FETCH_CAMPAIGN_CONVERSATIONS,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || response.error || !response.data) {
					throw new Error()
				}
				return response.data && response.data.fetchCampaignConversations
			})
	}

	listCampaignConversations = async (
		fields: CampaignConversationsQuery
	): Promise<ConversationList> => {
		return await ApiService.client
			.query({
				query: this.LIST_CAMPAIGN_CONVERSATIONS,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || response.error || !response.data) {
					throw new Error()
				}
				return {
					conversations: response.data!.listCampaignConversations,
					totalConversations: response.data!.totalCampaignConversations,
				}
			})
	}

	getCampaignConversation = async (
		input: CampaignConversationQuery
	): Promise<CampaignConversation> => {
		return await ApiService.client
			.query<{
				getCampaignConversation: CampaignConversation
				input: CampaignConversationQuery
			}>({
				query: this.GET_CAMPAIGN_CONVERSATION,
				variables: { id: input.campaignConversationId },
			})
			.then(response => {
				if (!response || response.error || !response.data) {
					throw new Error()
				}
				return response.data && response.data.getCampaignConversation
			})
	}

	listBroadcastResponses = async (
		fields: CampaignBroadcastResponsesQuery
	): Promise<CampaignBroadcastResponse> => {
		return await ApiService.client
			.query<{
				listBroadcastResponses: CampaignBroadcastResponse
				input: CampaignBroadcastResponsesQuery
			}>({
				query: this.GET_BROADCAST_RESPONSES,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || response.error || !response.data) {
					throw new Error()
				}
				return response.data && response.data.listBroadcastResponses
			})
	}

	duplicateCampaign = async (
		fields: DuplicateCampaignMutation
	): Promise<Campaign> => {
		return await ApiService.client
			.mutate<{
				duplicateCampaign: Campaign
				input: DuplicateCampaignMutation
			}>({
				mutation: this.DUPLICATE_CAMPAIGNS,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.duplicateCampaign
			})
	}

	createOptinFlowOutboundMessage = async (
		fields: CampaignCreateOptinFlowMessageFields
	): Promise<CampaignOptinFlowMessage> => {
		return await ApiService.client
			.mutate<
				{ createOptinFlowMessage: CampaignOptinFlowMessage },
				{ input: CampaignCreateOptinFlowMessageFields }
			>({
				mutation: this.CREATE_CAMPAIGN_OPTIN_FLOW_OUTBOUND_MESSAGES,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createOptinFlowMessage
			})
	}

	deleteOptinFlowOutboundMessage = async (
		fields: CampaignDeleteOptinFlowMessageFields
	): Promise<CampaignOptinFlowMessage> => {
		return await ApiService.client
			.mutate<
				{ deleteOptinFlowMessage: CampaignOptinFlowMessage },
				{ input: CampaignDeleteOptinFlowMessageFields }
			>({
				mutation: this.DELETE_CAMPAIGN_OPTIN_FLOW_OUTBOUND_MESSAGES,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.deleteOptinFlowMessage
			})
	}

	updateOptinFlowOutboundMessage = async (
		fields: CampaignUpdateOptinFlowMessageFields
	): Promise<CampaignOptinFlowMessage> => {
		return await ApiService.client
			.mutate<
				{ updateOptinFlowMessage: CampaignOptinFlowMessage },
				{ input: CampaignUpdateOptinFlowMessageFields }
			>({
				mutation: this.UPDATE_CAMPAIGN_OPTIN_FLOW_OUTBOUND_MESSAGES,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.updateOptinFlowMessage
			})
	}

	getCamapignOptinFlowOutboundMessages = async (): Promise<
		CampaignOptinFlowMessage[]
	> => {
		return await ApiService.client
			.query({
				query: this.GET_CAMPAIGN_OPTIN_FLOW_OUTBOUND_MESSAGES,
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.listOptinFlowMessages
			})
	}

	getCamapignOptinFlowInboundMessages = async (): Promise<
		CampaignOptinFlowInboundMessage[]
	> => {
		return await ApiService.client
			.query({
				query: this.GET_CAMPAIGN_OPTIN_FLOW_INBOUND_MESSAGES,
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.listOrgOptinFlowMessages
			})
	}

	createOptinFlowInboundMessage = async (
		fields: CampaignCreateOptinFlowMessageFields
	): Promise<CampaignOptinFlowInboundMessage> => {
		return await ApiService.client
			.mutate<
				{ createOrgOptinFlowMessage: CampaignOptinFlowInboundMessage },
				{ input: CampaignCreateOptinFlowMessageFields }
			>({
				mutation: this.CREATE_CAMPAIGN_OPTIN_FLOW_INBOUND_MESSAGES,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createOrgOptinFlowMessage
			})
	}

	deleteOptinFlowInboundMessage = async (
		fields: CampaignDeleteOptinFlowMessageFields
	): Promise<CampaignOptinFlowInboundMessage> => {
		return await ApiService.client
			.mutate<
				{ deleteOrgOptinFlowMessage: CampaignOptinFlowInboundMessage },
				{ input: CampaignDeleteOptinFlowMessageFields }
			>({
				mutation: this.DELETE_CAMPAIGN_OPTIN_FLOW_INBOUND_MESSAGES,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.deleteOrgOptinFlowMessage
			})
	}

	updateOptinFlowInboundMessage = async (
		fields: CampaignUpdateOptinFlowMessageFields
	): Promise<CampaignOptinFlowInboundMessage> => {
		return await ApiService.client
			.mutate<
				{ updateOrgOptinFlowMessage: CampaignOptinFlowInboundMessage },
				{ input: CampaignUpdateOptinFlowMessageFields }
			>({
				mutation: this.UPDATE_CAMPAIGN_OPTIN_FLOW_INBOUND_MESSAGES,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.updateOrgOptinFlowMessage
			})
	}

	startOptinFlowCampaign = async (
		fields: StartOptinFlowCampaign
	): Promise<Contact> => {
		return await ApiService.client
			.mutate<{ startOptinFlow: Contact }, { input: StartOptinFlowCampaign }>({
				mutation: this.START_OPTIN_FLOW_MESSAGE_CAMPAIGN,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.startOptinFlow
			})
	}

	optInContactCampaign = async (
		fields: OptInContactCampaign
	): Promise<Contact> => {
		return await ApiService.client
			.mutate<{ optInContact: Contact }, { input: OptInContactCampaign }>({
				mutation: this.OPTIN_CONTACT_CAMPAIGN,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.optInContact
			})
	}

	listTemplates = async (fields?: ListTemplatesQuery): Promise<Template[]> => {
		return await ApiService.client
			.query({
				query: this.LIST_TEMPLATES,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.listTemplates
			})
	}

	listGoals = async (fields?: ListGoalsQuery): Promise<IGoal[]> => {
		return await ApiService.client
			.query({
				query: this.LIST_GOALS,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.listGoals
			})
	}

	createCampaignTemplate = async (
		fields: CreateCampaignTemplateFields
	): Promise<Template> => {
		return await ApiService.client
			.mutate<
				{ createCampaignTemplate: Template },
				{ input: CreateCampaignTemplateFields }
			>({
				mutation: this.CREATE_CAMPAIGN_TEMPLATE,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createCampaignTemplate
			})
	}

	createKeywordCampaignTemplate = async (
		fields: CreateKeywordCampaignTemplateFields
	): Promise<Template> => {
		return await ApiService.client
			.mutate<
				{ createKeywordCampaignTemplate: Template },
				{ input: CreateKeywordCampaignTemplateFields }
			>({
				mutation: this.CREATE_KEYWORD_CAMPAIGN_TEMPLATE,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createKeywordCampaignTemplate
			})
	}

	archiveCampaign = async (fields: ArchiveFields): Promise<Campaign> => {
		return await ApiService.client
			.mutate<{ archiveCampaign: Campaign }, { input: ArchiveFields }>({
				mutation: this.ARCHIVE_CAMPAIGN,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.archiveCampaign
			})
	}
}

export default new CampaignService()
