import { MESSAGE } from 'services/messages/messages.gpl'
import { CONTACT, CONTACT_REPORT } from 'services/gpl/contact'

export const CAMPAIGN = `
    id
    contacts {
        id
        firstName
        lastName
        phone
        conversation {
            id
        }
    }
    status
    keyword
    leadsNeverCold
    message
    name
    organization {
        id
        tags {
            name
        }
    }
    user {
        id
        phoneTransferStatus
        firstName
        lastName
    }
    keywordCampaignConversation {
        id
    }
    contactGroups {
      id
      name
    }
    tags {
        id
        name
        campaignAudience
	    campaignType
	    campaignGoal
    }
    photo
    createdAt
    updatedAt
    autoResponses {
        message
        contactType
        id
        hasVcfAttached
    }
    campaignType
    hasVcfAttached
    scheduledAt
`
export const CAMPAIGN_REPORT = `
    id
    contacts {
        ${CONTACT_REPORT}
    }
    status
    keyword
    leadsNeverCold
    message
    name
    organization {
        id
        tags {
            name
        }
    }
    user {
        id
        phoneTransferStatus
        firstName
        lastName
    }
    keywordCampaignConversation {
        id
    }
    contactGroups {
      id
      name
    }
    tags {
        id
        name
        campaignAudience
	    campaignType
	    campaignGoal
    }
    photo
    createdAt
    updatedAt
    autoResponses {
        message
        contactType
        id
        hasVcfAttached
    }
    campaignType
    hasVcfAttached
    scheduledAt
`

export const CAMPAIGN_LIST = `
    id
    contacts {
        id
    }
    status
    keyword
    leadsNeverCold
    message
    name
    user {
        firstName
        lastName
    }
    createdAt
    updatedAt
    autoResponses {
        message
        contactType
        id
        hasVcfAttached
    }
    scheduledAt
`

export const CAMPAIGN_BASIC = `
    id
    name
    createdAt
    updatedAt
`

export const CAMPAIGN_CONVERSATIONS = `
    campaign {
        ${CAMPAIGN}
    }
    conversationLabel
    id
    lastMessageCreatedAt
    messages{
        ${MESSAGE}
    }
    name
    updatedAt
`

export const CAMPAIGN_OPTIN_FLOW_MESSAGE = `
    id
    message
    messageType
    updatedAt
    user {
        email
    }
    updatedAt
    hasVcfAttached
`
export const CAMPAIGN_INBOUND_OPTIN_FLOW_MESSAGE = `
    id
    message
    messageType
    updatedAt
    organization{
        id
        name
    }
      user{
        firstName
        email
    }
    hasVcfAttached
`
export const TEMPLATE = `
    id
    type
    keyword
    description
    autoResponseTemplates {
        contactType
        message
    }
    campaigns {
        keyword
        id
    }
    leadsNeverCold
    isAdminTemplate
    template {
      id
    }
    name
    message
    user {
        id
        firstName
        lastName
    }
    contactGroups {
      id
      name
    }
    tags {
        id
        name
        campaignAudience
        campaignType
        campaignGoal
    }
    goals {
      id
      name
    }
    photo
    finraLetter
    finraLetterDate
    giphyLink
    thumbnail
    createdAt
    updatedAt
`

export const GOAL = `
    id
    name
    createdAt
    updatedAt
`
