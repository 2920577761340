import { createAsyncThunk } from '@reduxjs/toolkit'
import OrganizationService from 'services/organization'

export const createOrganization = createAsyncThunk(
	'organization/create',
	OrganizationService.createOrganization
)
export const updateOrganization = createAsyncThunk(
	'organization/update',
	OrganizationService.updateOrganization
)
export const updateOrganizationName = createAsyncThunk(
	'organization/update-name',
	OrganizationService.updateOrganizationName
)
export const updateOrganizationAdvisorName = createAsyncThunk(
	'organization/update-advisor-name',
	OrganizationService.updateOrganizationAdvisorName
)
export const updateOrganizationLogo = createAsyncThunk(
	'organization/update-logo',
	OrganizationService.updateOrganizationLogo
)
export const updateOrganizationBilling = createAsyncThunk(
	'organization/update/billing',
	OrganizationService.updateOrganizationBilling
)
export const toggleOptinFlow = createAsyncThunk(
	'organization/toggle-optin-flow',
	OrganizationService.toggleOptinFlow
)
export const toggleNotificationSettings = createAsyncThunk(
	'organization/toggle/receive-notification',
	OrganizationService.toggleNotificationSettings
)

export const createOrganizationUser = createAsyncThunk(
	'organization/create/user',
	OrganizationService.createOrganizationUser
)

export const getOrganizations = createAsyncThunk(
	'organization/list',
	OrganizationService.getOrganizations
)

export const getOrganizationsByParentId = createAsyncThunk(
	'organization/list/parent/id',
	OrganizationService.getOrganizations
)

export const updateOrganizationsOptinKeyword = createAsyncThunk(
	'organization/update/optin-keyword',
	OrganizationService.updateOrganizationOptinKeyword
)

export const toggleOptinByFirstSms = createAsyncThunk(
	'organization/toggle/optin-by-sms',
	OrganizationService.toggleOptinByFirstSms
)

export const toggleOptinByOrgOptinKeyword = createAsyncThunk(
	'organization/toggle/optin-by-org-keyword',
	OrganizationService.toggleOptinByOrgKeyword
)

export const toggleRestrictBulkMsgs = createAsyncThunk(
	'organization/toggle/restrict-bulk-sms',
	OrganizationService.toggleRestrictBulkMsgs
)

export const toggleRestrictAllMsgs = createAsyncThunk(
	'organization/toggle/restrict-all-sms',
	OrganizationService.toggleRestrictAllMsgs
)

export const toggleAutosendOutboundMsgs = createAsyncThunk(
	'organization/toggle/restrict-autosend-outbound-flow',
	OrganizationService.toggleAutosendOutboundMsgs
)
export const toggleContactsOptInSetting = createAsyncThunk(
	'organization/toggle/allow-contact-optin',
	OrganizationService.toggleContactsOptInSetting
)
